import React from "react";
import Select from "react-select";
import Animated from "react-select/animated";

let styles = {
    container: (provided) => ({
        ...provided,
        display: "inline-block",
        position: "relative",
        width: "100%"
    }),
    control: (provided, state) => ({
        ...provided,
        borderRadius: "4px",
        border: "1px solid #ebebeb",
        borderColor: "#ebebeb",
        background: "#fff",
        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.04)",
        height: "36px",

        "&:hover": {
            borderColor: "#ebebeb"
        }
    })
};

const AutoComplete = ({
    className = "",
    options,
    onInputChange = () => {},
    onChange,
    handleBlur = () => {},
    customStyles = undefined,
    maxMenuHeight,
    isClearable = false,
    placeholder = "",
    isLoading = false,
    value,
    isMulti = false,
    handleScroll = undefined,
    handleOnFocus = undefined,
    selectRef,
    isOptionDisabled = undefined,
    isDisabled,
    closeMenuOnSelect = true,
    label = "",
    ...rest
}) => {
    styles = customStyles ? customStyles : styles;
    return (
        <div>
            {label && <p className="input-label fw-bold mb-2">{label}</p>}
            <Select
                isDisabled={isDisabled}
                onFocus={handleOnFocus}
                onBlur={handleBlur}
                ref={selectRef}
                onMenuScrollToBottom={handleScroll}
                className={className}
                options={options}
                onInputChange={onInputChange}
                onChange={onChange}
                styles={styles}
                maxMenuHeight={maxMenuHeight}
                isClearable={isClearable}
                placeholder={placeholder}
                closeMenuOnSelect={closeMenuOnSelect}
                isLoading={isLoading}
                value={value}
                isOptionDisabled={isOptionDisabled}
                isMulti={isMulti}
                components={Animated()}
                {...rest}
            />
        </div>
    );
};

export default AutoComplete;
