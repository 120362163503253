import * as Yup from "yup";
import { LABLES, NAMES } from "./ContentFieldsNames";
import { ValidationMessages } from '../../constants';

let { ARTICLE_NAME, WEBSITE_NAME, WEBSITE_LINK, TEASER_TEXT, SUBTITLES, SKILL_LEVEL, TOPIC } =
LABLES;

export const initialValues = {
    updateContent: {
      [NAMES[ARTICLE_NAME]]: "",
      [NAMES[TEASER_TEXT]]: "",
      [NAMES[WEBSITE_LINK]]: "",
      [NAMES[WEBSITE_NAME]]: "",
      [NAMES[SUBTITLES]] : "",
      [NAMES[SKILL_LEVEL]]: "",
      [NAMES[TOPIC]]: "",
    }
}; 

export const validationMessagesImage = {
      formatError: ValidationMessages.imageFormatValidation,
      sizeError: ValidationMessages.imageSizeValidation,
      requiredError: ValidationMessages.imageRequiredValidationArticle
}
 

export const updateContentFormValidationSchema = Yup.object({
      [NAMES[ARTICLE_NAME]]: Yup.string().required("Please enter the Article Name")
            .min(4, "Article Name should be minimum 4 characters long")
            .max(100, "Please enter between 4 and 100 characters.")
            .matches(/^[a-zA-Z0-9_.,!'"?/$-/\s]+$/, `Article Name should only contain alphabets, numbers, and any of the following characters: , . ! - ' " _ / $ ?`),
      [NAMES[TEASER_TEXT]]: Yup.string().required("Please enter a teaser text")
            .min(4, "Teaser Text should be minimum 4 characters long")
            .max(256, "Please enter between 4 and 256 characters."),
            // .matches(/^[a-zA-Z0-9_.,!'"?/$-/\s]+$/, `Teaser Text should only contain alphabets, numbers, and any of the following characters: , . ! - ' " _ / $ ?`),
      [NAMES[WEBSITE_LINK]]: Yup.string()
            .required("Please enter the Website link.")
            .matches(/\b(http|https):\/\//, "Url must start with https:// or http://")
            .url("Kindly enter valid URL.")
            .min(5, "Website Link should be minimum 5 characters long")
            .max(256, "Please enter between 5 and 256 characters."),
      [NAMES[WEBSITE_NAME]]: Yup.string().required("Please enter the website’s name.")
            .min(4, "Website Name should be minimum 4 characters long")
            .max(50, "Please enter between 4 and 50 characters.")
            .matches(/^[a-zA-Z0-9_.,!'"?/$-/\s]+$/, `Website Name should only contain alphabets, numbers, and any of the following characters: , . ! - ' " _ / $ ?`),
      [NAMES[SUBTITLES]]: Yup.string().required("Please enter a subtitle.")
            .min(4, "Subtitle should be minimum 4 characters long")
            .max(100, "Please enter between 4 and 100 characters.")
            .matches(/^[a-zA-Z0-9_.,!'"?/$-/\s]+$/, `Subtitle should only contain alphabets, numbers, and any of the following characters: , . ! - ' " _ / $ ?`),
      [NAMES[SKILL_LEVEL]]: Yup.string().required("Select Skill Level"),
      [NAMES[TOPIC]]: Yup.string().required("Select Topic")
    });

