// baseCode

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import logoDark from "../../assets/images/logo-dark.png";
import APP_CONFIG from "../../APP_CONFIG";
import SidebarContent from "./SidebarContent";

const Sidebar = (props) => {
    return (
        <>
            <div className="vertical-menu gt-sidebar">
                {/* <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={logo} alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src={logoDark} alt="" height="17" />
                        </span>
                    </Link>

                    <Link to="/" className="logo logo-light">
                        <span className="logo-sm">
                            <img
                                className="mt-2 "
                                src={APP_CONFIG.IMAGES.COLLAPSED_SIDEBAR_APP_LOGO}
                                alt="logo"
                                height={APP_CONFIG.COLLAPSED_SIDEBAR_APP_LOGO.height}
                                width={APP_CONFIG.COLLAPSED_SIDEBAR_APP_LOGO.width}
                            />
                        </span>
                        <span className="logo-lg">
                            <img
                                className="mt-2"
                                src={APP_CONFIG.IMAGES.OPEN_SIDEBAR_APP_LOGO}
                                alt="logo"
                                height={APP_CONFIG.OPEN_SIDEBAR_APP_LOGO_SIZE.height}
                                width={APP_CONFIG.OPEN_SIDEBAR_APP_LOGO_SIZE.width}
                            />
                        </span>
                    </Link>
                </div> */}
                <div data-simplebar className="h-100">
                    {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
                </div>
                <div className="sidebar-background"></div>
            </div>
        </>
    );
};

const mapStatetoProps = (state) => {
    return {
        layout: state.Layout
    };
};
export default connect(mapStatetoProps, {})(withRouter(withTranslation()(Sidebar)));
