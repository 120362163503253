export let LABLES = {
    ADVERTISEMENT_LINK: "Advertisement Link",
    ADVERTISEMENT_NAME: "Advertisement Name",
    START_DATE: "Start Date / Time",
    END_DATE: "End Date / Time",
    ADVERTISERS_NAME: "Advertiser's Name",
    ADVERTISERS_EMAIL: "Advertiser's Email",
    ADD_IMGAE: "Advertisement Image"
};

export let NAMES = {
    [LABLES.ADD_IMGAE]: "adImage",
    [LABLES.START_DATE]: "startDate",
    [LABLES.END_DATE]: "endDate",
    [LABLES.ADVERTISEMENT_LINK]: "advertisementLink",
    [LABLES.ADVERTISEMENT_NAME]: "advertisementName",
    [LABLES.ADVERTISERS_EMAIL]: "advertiserEmail",
    [LABLES.ADVERTISERS_NAME]: "advertiserName"
};

export let PLACEHOLDERS = {
    [LABLES.START_DATE]: "08/11/2022 11:15",
    [LABLES.END_DATE]: "08/11/2023 11:15",
    [LABLES.ADVERTISEMENT_LINK]: "DGPT.com",
    [LABLES.ADVERTISEMENT_NAME]: "Disc Golf Pro Tour",
    [LABLES.ADVERTISERS_EMAIL]: "disgolf@exmaple.com",
    [LABLES.ADVERTISERS_NAME]: "Disc Golf Pro Tour"
};
