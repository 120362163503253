import { useState } from "react";
import { CustomButton } from "../../components/CustomButton"
import { imageUploadHandler } from '../../utils/mediaUtils';
import UploadImage from '../../assets/images/upload-file-img.png';
import { ImportCourses } from '../../api/api.service';
import toast from "react-hot-toast";
import importCoursesFile from '../../assets/samplefile/pured_template.csv';

const fileTypes = [
    "xlsx","xls", "csv"]
const errMessage = "Please upload valid file format (.xls,.xlsx).";
const BEErrorMessage = "Error uploading CSV"
const ImportCoursesModal = ({
  onNo = () => {},
  refetchListing=() => { },
  yesLoading = false,
}) => {
    const [imageError, setImageError] = useState(undefined);
    const [fileUploaded, setFileUploaded] = useState(undefined);
    const [fileForBE, setFileForBE] = useState(undefined);
    const [isImportingCourses, setImportingCourses] = useState(false);

    const initImgUpload = (e) => {
        let file = e.target.files[0];
        setFileForBE(e.target.files);
        if (file) {
            imageUploadHandler({file, 
                imagePreviewCB: ()=>{}, 
                imageErrorCB: setImageError, 
                uploadImgCB: setFileUploaded, 
                fileTypes: fileTypes,
                customErrorMessage: errMessage});
        }
    };

    const handleImportFile = () => {
        if(!fileForBE){
            toast.error("kindly select a file.");
            return
        }else {
                setImportingCourses(true);
                const data = new FormData();
                data.append("file", fileForBE[0]);
                ImportCourses(data).then(res => {
                    if(res.Message == BEErrorMessage){
                        setImportingCourses(false);
                    }else{
                        toast.success("courses imported successfully.");
                        onNo()
                        refetchListing()
                    }
                    setImportingCourses(false);
                }).catch(err => {console.log('err', err)
                setImportingCourses(false);});
        }
    }

    const handleSampleFileDownload = () => {
        const csvLink = importCoursesFile;
        const link = document.createElement("a");
        link.href = csvLink;
        link.download = "courses_import_sample.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

  return(
    <>
      <div className="d-flex flex-column align-items-center">

        <div className="mb-3">
            <h4 className="font-clr-theme-secondary fw-bold text-center">
                {"Import CSV"}
            </h4>
            <p className="my-4 font-clr-theme-secondary"><b>Note:</b> Uploading file should have similar format that of sample file.{" "} 
            <span className="download-file-typo" onClick={handleSampleFileDownload}>Click to download a sample.</span></p>
            
        </div>

        <div className={`image-upload-con`}>
            <>
                <input
                    hidden
                    type="file"
                    accept=".xlsx,.xls,.csv"
                    name="file"
                    id="img-input-id"
                    onChange={initImgUpload}
                    disabled={isImportingCourses}
                /> 
                <label
                    htmlFor="img-input-id"
                    className="place-order-image-con d-flex justify-content-center align-items-center case-detail-accordian-body-img size-modifier"
                >
                    <img
                        src={UploadImage}
                        className={`preview-image`}
                        alt="file to upload"
                    />
                </label>
            </>
            {fileUploaded && (
                <span className="file-name-typo">{fileUploaded?.name}</span>
            )}
            {imageError && (
                <span className="d-block text-danger file-upload-error mt-1">{imageError}</span>
            )}
        </div>

        <div className="d-flex justify-content-evenly btns-main-con ">
            <CustomButton
                title="Import"
                color="primary"
                className="custom-btn-sm shadow-theme fw-bold"
                onClick={handleImportFile}
                loading={isImportingCourses}
                disabled={isImportingCourses}
            />
            <CustomButton
                title={"Cancel"}
                className="me-3 custom-btn-sm fw-bold"
                color="primary"
                outline
                onClick={onNo}
                loading={isImportingCourses}
                disabled={isImportingCourses}
            />
        </div>
      </div>
    </>
  )
}
export default ImportCoursesModal