import React, { useState } from "react";
import { Row, Col } from "reactstrap";

import AnalyticsBoxUI from "./AnalyticsBoxesUI";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import totalUsersImg from "../../assets/images/app/dashboard-page/total-users.svg";
import totalBlogsImg from "../../assets/images/app/dashboard-page/total-blogs.svg";
import totalCoursesImg from "../../assets/images/app/dashboard-page/total-courses.svg";
import totalRoundsImg from "../../assets/images/app/dashboard-page/total-rounds.svg";
import { MODULE_KEYS } from "../../api/api.keys";
import { GetDashboardAnalytics } from "../../api/api.service";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { hasData } from "../../utils/commonUtils";

const ANALYTICS_CONFIG = [
    {
        resKey: "TotalUsers",
        title: "Total Users",
        iconUrl: totalUsersImg,
        description: 0,
        showOptions: false
    },
    {
        resKey: "TotalArticles",
        title: "Total Articles/Blogs",
        iconUrl: totalBlogsImg,
        description: 0,
        showOptions: false
    },
    {
        resKey: "TotalCourses",
        title: "Total Courses",
        iconUrl: totalCoursesImg,
        description: 0,
        showOptions: false
    },
    {
        resKey: "TotalRounds",
        title: "Total Rounds",
        iconUrl: totalRoundsImg,
        description: 0,
        showOptions: false
    }
];

const AnalyticsSection = ({}) => {
    let { useFetch } = useRequestProcessor();
    const [analyticsData, setAnalyticsData] = useState([]);
    let { data, isFetching } = useFetch(
        [MODULE_KEYS.ACCOUNT_CHANGE_PASSWORDDASHBOARD_ANALYTICS],
        async ({ signal }) => await GetDashboardAnalytics({}, signal),
        {
            onSuccess: (res) => {
                const updatedConfig = ANALYTICS_CONFIG.map((item, key) => {
                    item.description = res[item.resKey];
                    return item;
                });
                setAnalyticsData(updatedConfig);
            }
        }
    );

    if (isFetching || !hasData(data)) {
        return (
            <Row>
                <Col>
                    <LoaderFallbackUI
                        isLoader={isFetching}
                        className="roundsection-loader analytics-loader-con"
                    />
                </Col>
            </Row>
        );
    }

    return (
        <Row>
            {analyticsData.map((item, key) => (
                <Col md={3} key={"_col_" + key}>
                    <AnalyticsBoxUI showOptions={item.showOptions} data={item} />
                </Col>
            ))}
        </Row>
    );
};

export default AnalyticsSection;
