export const APP_ROUTES = {
    // Authentication
    LOGIN: "/login",
    LOGOUT: "/logout",
    FORGET_PASSWORD: "/forgot-password",
    FORGET_VERIFY_CODE: "/forget-verify-code/:tokenId",
    RESET_PASSWORD: "/reset-password/:tokenUUId",
    REGISTER: "/register",
    // Dashboard
    DASHBOARD: "/dashboard",
    // User Management
    USER_MANAGEMENT: "/users",
    USER_MANAGEMENT_DETAILS: "/users/:userId",
    USER_MANAGEMENT_PROFILE: "/users/:userId/profile",
    USER_MANAGEMENT_CONNECTIONS: "/users/:userId/connections",
    USER_MANAGEMENT_ROUNDS: "/users/:userId/rounds",
    // User Management
    CONTENT_MANAGEMENT: "/contents",
    CONTENT_MANAGEMENT_ADD: "/contents/create",
    CONTENT_MANAGEMENT_DETAILS: "/contents/:contentId",
    CONTENT_MANAGEMENT_UPDATE: "/contents/update/:contentId",
    // Course Management
    COURSE_MANAGEMENT: "/courses",
    COURSE_MANAGEMENT_DETAILS: "/courses/:courseId",
    COURSE_DETAILS: "/courses/:courseId/details",
    COURSE_DETAILS_ROUNDS: "/courses/:courseId/rounds",
    UPDATE_COURSE: "/courses/update/:courseId",
    ADD_COURSE: "/add-course",
    // Ad Management
    AD_MANAGEMENT: "/ads",
    CREATE_AD: "/create-ad",
    AD_DETAILS: "/ads/:adId",
    UPDATE_AD: "/ads/update/:adId",
    // Account
    ACCOUNT: "/account",
    ACCOUNT_PROFILE: "/account/profile",
    UPDATE_ACCOUNT_PROFILE: "/account/profile/update",
    ACCOUNT_CHANGE_PASSWORD: "/account/update"
};

export const getParamsAttachedRoute = (route, obj = {}) => {
    let keys = Object.keys(obj);

    if (keys?.length) {
        let objectKeys = keys;

        objectKeys.forEach(() => {
            route = route.replace(new RegExp(/:([\d\w?])+/, "i"), (match) => {
                let formattedMatchedValue =
                    match[match.length - 1] === "?"
                        ? match.slice(1, match.length - 1)
                        : match.slice(1);
                return obj[formattedMatchedValue];
            });
        });
        return route;
    }
    return route;
};
