import * as Yup from "yup";
import { LABLES, NAMES } from "./AdsFieldsNames";
import { ValidationMessages } from "../../constants";
let { ADVERTISEMENT_LINK, ADVERTISEMENT_NAME, ADVERTISERS_NAME, ADVERTISERS_EMAIL } = LABLES;

export const initialValues = {
    adManagementFields: {
        [NAMES[ADVERTISEMENT_LINK]]: "",
        [NAMES[ADVERTISEMENT_NAME]]: "",
        [NAMES[ADVERTISERS_NAME]]: "",
        [NAMES[ADVERTISERS_EMAIL]]: ""
    }
};

export const validationMessagesImage = {
    formatError: ValidationMessages.imageFormatValidation,
    sizeError: ValidationMessages.imageSizeValidation,
    requiredError: ValidationMessages.imageRequiredValidationAd
};

export const adManagementFormValidationSchema = Yup.object({
    [NAMES[ADVERTISEMENT_LINK]]: Yup.string()
        .required("Please enter the Website link.")
        .matches(/\b(http|https):\/\//, "Url must start with https:// or http://")
        .url("Kindly enter valid URL.")
        .min(1, "Advertisement Link should be minimum 1 characters long")
        .max(200, "Please enter between 1 and 200 characters."),
    [NAMES[ADVERTISEMENT_NAME]]: Yup.string()
        .required("Please enter the Advertisement's Name.")
        .min(1, "Advertisement Name should be minimum 1 characters long")
        .max(50, "Please enter between 1 and 50 characters."),
    //   .matches(/^[A-Za-z ]*$/, "Please enter alphabets only."),
    [NAMES[ADVERTISERS_NAME]]: Yup.string()
        .required("Please enter the Advertiser's name.")
        .min(1, "Advertiser's Name should be minimum 1 characters long")
        .max(50, "Please enter between 1 and 50 characters.")
        .matches(/^[A-Za-z ]*$/, "Please enter alphabets only."),
    [NAMES[ADVERTISERS_EMAIL]]: Yup.string()
        .email("Enter a valid email address")
        .required("Please enter advertiser's email address.")
        .matches(
            /([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/,
            "Enter valid email address"
        )
});
