import { AdStatus } from "../../constants";

const AdActions = {
  ACTIVATE: 1,
  DEACTIVATE: 0
}; 

export const getStatus = (status) => (status === AdStatus.RUNNING ? AdStatus.INACTIVE : AdStatus.ACTIVE );
export const getStatusRunningOrCanceled = (status) => (status === AdStatus.ACTIVE ? AdStatus.RUNNING : AdStatus.CANCELED)
export const findStatusChecked = (status) => (status === AdStatus.RUNNING ? AdActions.ACTIVATE : AdActions.DEACTIVATE )
export const handleAdModal = (setState, state) => setState(!state);