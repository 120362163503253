import { injectBaseConstantMethods } from "./BaseConstants";

const TopicOptions = {
    Events: "Events",
    News: "News",
    Equipment: "Equipment",
    Coaching: "Coaching"
};

const displayTextKeys = {
    [TopicOptions.Events]: "Events",
    [TopicOptions.News]: "News",
    [TopicOptions.Equipment]: "Equipment",
    [TopicOptions.Coaching]: "Coaching"
};

const labelClass = {
    [TopicOptions.Events]: "round-events",
    [TopicOptions.News]: "round-news",
    [TopicOptions.Equipment]: "round-Equipment",
    [TopicOptions.Coaching]: "round-coaching"
};

export default injectBaseConstantMethods(TopicOptions, displayTextKeys, labelClass);