import React from "react";
import { Card, CardBody, Row, Col, Label } from "reactstrap";

import { CustomButton } from "../../components/CustomButton";
import { ImageBox } from "../../components/ImageBox";
import { EditableDetail } from "../../components/EditableDetail";
import { updateContentFormValidationSchema, validationMessagesImage } from "./FormValidations";
import { useFormik } from "formik";
import { AutoComplete } from "../../components/AutoComplete";
import { SkillLevelArticleOptions, TopicOptionsList, SkillLevelArticle } from "../../constants";
import { CustomText } from "../../components/CustomText";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";
import { customStyles } from "./CustomStyles";

const ContentDataStructureUI = ({
    isUpdate = false,
    isDetailView = false,
    FIELDS_TITLE = {},
    FIELDS_NAME = {},
    onCancel = () => {},
    onSave = () => {},
    onEdit = () => {},
    data = {},
    setContentImage = () => {},
    isFormSubmitting = false,
    children
}) => {
    let {
        ARTICLE_NAME,
        WEBSITE_NAME,
        WEBSITE_LINK,
        TEASER_TEXT,
        TOTAL_CLICKS,
        SUBTITLES,
        SKILL_LEVEL,
        TOPIC
    } = FIELDS_TITLE;
    const defaultFormData = {
        [FIELDS_NAME[ARTICLE_NAME]]: data?.Title || "",
        [FIELDS_NAME[WEBSITE_NAME]]: data?.WebsiteName || "",
        [FIELDS_NAME[WEBSITE_LINK]]: data?.Url || "",
        [FIELDS_NAME[SUBTITLES]]: data?.Subtitle || "",
        [FIELDS_NAME[TEASER_TEXT]]: data?.Description || "",
        [FIELDS_NAME[SKILL_LEVEL]]: SkillLevelArticle.getDisplayTextKey(data?.SkillLevel) || "",
        [FIELDS_NAME[TOPIC]]: data?.Topic || ""
    };

    // input fields validation
    const formik = useFormik({
        initialValues: { ...defaultFormData },
        enableReinitialize: true,
        validationSchema: updateContentFormValidationSchema,
        onSubmit: (values) => {
            onSave(values);
        }
    });

    const columnsFourToSix = isUpdate ? 6 : 4;
    return (
        <Card className="custom-card">
            <form onSubmit={formik.handleSubmit}>
                <CardBody className="pb-5">
                    {children}
                    <Row>
                        <Col sm="12" md="3" lg="3" className="pb-5">
                            <>
                                <ImageBox
                                    containerClasses="w-100 image-box-con "
                                    isEditable={isUpdate}
                                    imgUrl={data?.Media?.OriginalUrl || ""}
                                    onImageUpload={setContentImage}
                                    imageErrors={validationMessagesImage}
                                />
                            </>
                        </Col>

                        <Col sm="12" md="9" lg="9">
                            <Row>
                                <Col sm="12" md="4" lg="4" className="mt-3 mt-md-0">
                                    <EditableDetail
                                        inputClasses="content-input"
                                        isUpdate={isUpdate}
                                        isDetails={isDetailView}
                                        title={ARTICLE_NAME}
                                        name={FIELDS_NAME[ARTICLE_NAME]}
                                        value={formik.values[FIELDS_NAME[ARTICLE_NAME]]}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleChange}
                                    />
                                    <FormikErrorText
                                        formikInstance={formik}
                                        fieldName={[FIELDS_NAME[ARTICLE_NAME]]}
                                    />
                                </Col>
                                <Col sm="12" md="4" lg="4" className="mt-3 mt-md-0">
                                    <EditableDetail
                                        inputClasses="content-input"
                                        isUpdate={isUpdate}
                                        isDetails={isDetailView}
                                        title={WEBSITE_NAME}
                                        name={FIELDS_NAME[WEBSITE_NAME]}
                                        value={formik.values[FIELDS_NAME[WEBSITE_NAME]]}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleChange}
                                    />
                                    <FormikErrorText
                                        formikInstance={formik}
                                        fieldName={[FIELDS_NAME[WEBSITE_NAME]]}
                                    />
                                </Col>
                                <Col sm="12" md="4" lg="4" className="mt-3 mt-md-0">
                                    <EditableDetail
                                        inputClasses="content-input"
                                        isUpdate={isUpdate}
                                        isDetails={isDetailView}
                                        title={WEBSITE_LINK}
                                        name={FIELDS_NAME[WEBSITE_LINK]}
                                        value={formik.values[FIELDS_NAME[WEBSITE_LINK]]}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleChange}
                                    />
                                    <FormikErrorText
                                        formikInstance={formik}
                                        fieldName={[FIELDS_NAME[WEBSITE_LINK]]}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-lg-5 mt-md-5 ">
                                {isDetailView && (
                                    <Col sm="12" md="4" lg="4" className="mt-3 mt-md-0">
                                        <EditableDetail
                                            isDetails={isDetailView}
                                            title={TOTAL_CLICKS}
                                            name={FIELDS_NAME[TOTAL_CLICKS]}
                                            value={data[FIELDS_NAME[TOTAL_CLICKS]] || "0"}
                                            onChange={() => {}}
                                        />
                                    </Col>
                                )}

                                <Col
                                    sm="12"
                                    md={columnsFourToSix}
                                    lg={columnsFourToSix}
                                    className="mt-3 mt-md-0"
                                >
                                    {isUpdate && (
                                        <>
                                            <Label className="mb-2 skill-level-label fw-bold">
                                                Skill Level
                                            </Label>
                                            <AutoComplete
                                                styles={customStyles.skillLevel}
                                                onChange={(val) =>
                                                    formik.setFieldValue(
                                                        [FIELDS_NAME[SKILL_LEVEL]],
                                                        val.label
                                                    )
                                                }
                                                onBlur={() => {
                                                    formik.setFieldTouched(
                                                        FIELDS_NAME[SKILL_LEVEL]
                                                    );
                                                }}
                                                value={SkillLevelArticleOptions.filter(
                                                    (el) =>
                                                        el.label ===
                                                        formik.values[FIELDS_NAME[SKILL_LEVEL]]
                                                )}
                                                isSearchable={false}
                                                name={FIELDS_NAME[SKILL_LEVEL]}
                                                isDisabled={isDetailView}
                                                options={SkillLevelArticleOptions}
                                                placeholder="Select Skill Level"
                                            />
                                            <FormikErrorText
                                                formikInstance={formik}
                                                fieldName={[FIELDS_NAME[SKILL_LEVEL]]}
                                            />
                                        </>
                                    )}

                                    {isDetailView && (
                                        <CustomText
                                            title={SKILL_LEVEL}
                                            description={defaultFormData[FIELDS_NAME[SKILL_LEVEL]]}
                                        />
                                    )}
                                </Col>
                                <Col
                                    sm="12"
                                    md={columnsFourToSix}
                                    lg={columnsFourToSix}
                                    className="mt-3 mt-md-0"
                                >
                                    {isUpdate && (
                                        <>
                                            <Label className="mb-2 skill-level-label fw-bold">
                                                {TOPIC}
                                            </Label>
                                            <AutoComplete
                                                styles={customStyles.skillLevel}
                                                onChange={(val) =>
                                                    formik.setFieldValue(
                                                        [FIELDS_NAME[TOPIC]],
                                                        val.label
                                                    )
                                                }
                                                onBlur={() => {
                                                    formik.setFieldTouched(FIELDS_NAME[TOPIC]);
                                                }}
                                                value={TopicOptionsList.filter(
                                                    (el) =>
                                                        el.label ===
                                                        formik.values[FIELDS_NAME[TOPIC]]
                                                )}
                                                name={FIELDS_NAME[TOPIC]}
                                                isDisabled={isDetailView}
                                                options={TopicOptionsList}
                                                placeholder={TOPIC}
                                                isSearchable={false}
                                            />
                                            <FormikErrorText
                                                formikInstance={formik}
                                                fieldName={[FIELDS_NAME[TOPIC]]}
                                            />
                                        </>
                                    )}

                                    {isDetailView && (
                                        <CustomText
                                            title={TOPIC}
                                            description={defaultFormData[FIELDS_NAME[TOPIC]]}
                                        />
                                    )}
                                </Col>
                                <Col
                                    sm="12"
                                    md={6}
                                    lg={6}
                                    className={` ${
                                        isDetailView ? "mt-lg-5 mt-md-5" : " mt-md-5"
                                    } mt-3`}
                                >
                                    <EditableDetail
                                        inputClasses="content-input"
                                        isUpdate={isUpdate}
                                        isDetails={isDetailView}
                                        title={SUBTITLES}
                                        name={FIELDS_NAME[SUBTITLES]}
                                        value={formik.values[FIELDS_NAME[SUBTITLES]]}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleChange}
                                    />
                                    <FormikErrorText
                                        formikInstance={formik}
                                        fieldName={[FIELDS_NAME[SUBTITLES]]}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-lg-5 mt-md-5">
                                <Col sm="12" md="12" lg="12" className="mt-3 mt-md-0">
                                    <EditableDetail
                                        inputClasses="content-input"
                                        type="textarea"
                                        icon={false}
                                        isUpdate={isUpdate}
                                        isDetails={isDetailView}
                                        title={TEASER_TEXT}
                                        name={FIELDS_NAME[TEASER_TEXT]}
                                        value={formik.values[FIELDS_NAME[TEASER_TEXT]]}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleChange}
                                    />
                                    <FormikErrorText
                                        formikInstance={formik}
                                        fieldName={[FIELDS_NAME[TEASER_TEXT]]}
                                    />
                                </Col>
                            </Row>
                            {isDetailView && (
                                <Row className="mt-4 ">
                                    <Col
                                        sm="12"
                                        md="4"
                                        lg="4"
                                        className="content-mang-edit-btn-margin ms-0 ms-md-3"
                                    >
                                        <CustomButton
                                            title="Edit"
                                            color="primary"
                                            className=" custom-btn-lg"
                                            onClick={onEdit}
                                            type="button"
                                        />
                                    </Col>
                                </Row>
                            )}
                            {isUpdate && (
                                <div className="d-flex justify-content-evenly justify-content-lg-start justify-content-md-start flex-wrap mt-5">
                                    <CustomButton
                                        title="Save Changes"
                                        color="primary"
                                        className="custom-btn-lg"
                                        type="submit"
                                        loading={isFormSubmitting}
                                    />

                                    <CustomButton
                                        title="Cancel"
                                        outline
                                        color="primary"
                                        className="custom-btn-lg-secondary mx-2 mt-3 mt-md-0"
                                        onClick={onCancel}
                                        type="button"
                                        isDisabled={isFormSubmitting}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                </CardBody>
            </form>
        </Card>
    );
};

export default ContentDataStructureUI;
