export const customStyles = {
    datePicker: {
        container: (provided) => ({
            display: "inline-block",
            position: "relative",
            width: "100%",
            borderRadius: "4px",
            ...provided
        }),
        control: (provided, state) => ({
            ...provided,
            minHeight: "40px",
            borderColor: "#2795AE",
            boxShadow: state.isFocused ? 0 : 0,
            "&:hover": {
                borderWidth: "2px",
                borderColor: "#2795AE"
            }
        }),
        option: (provided, state) => ({
            ...provided,
            fontWeight: 500,
            color: state.isSelected ? "#fff" : "#000000",
            fontSize: "1rem",
            fontFamily: "Poppins",
            textAlign: "center"
        }),
       
    }
};
