import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <Container fluid={true}>
                    <Row>
                        <Col xs={12} className="fw-bold">
                            © Copyright {new Date().getFullYear()}{" "}
                            <u className="fw-normal">Channelsource</u> All Rights Reserved.
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
};

export default Footer;
