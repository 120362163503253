export let LABLES = {
    FULL_NAME: "Full Name",
    EMAIL: "Email",
    PASSWORD: "Password",
    NEW_PASSWORD: "New Password",
    CONFIRM_PASSWORD: "Confirm Password"
};

export let NAMES = {
    [LABLES.FULL_NAME]: "fullName",
    [LABLES.EMAIL]: "email",
    [LABLES.PASSWORD]: "oldPassword",
    [LABLES.NEW_PASSWORD]: "newPassword",
    [LABLES.CONFIRM_PASSWORD]: "confirmPassword",
};

export let PLACEHOLDERS = {
    [LABLES.FULL_NAME]: "",
    [LABLES.EMAIL]: "",
    [LABLES.PASSWORD]: "●●●●●●●●●●●●●●●●",
    [LABLES.NEW_PASSWORD]: "●●●●●●●●●●●●●●●●",
    [LABLES.CONFIRM_PASSWORD]: "●●●●●●●●●●●●●●●●",
};
