import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";
import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import { GetConnections } from "../../api/api.service";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { PageHeadings, SkillLevel, SortOrder } from "../../constants";
import { UserAvatar } from "../../components/Avatar";
import { handleFilterChange } from "../../utils/tableUtils";
import ListingHeader from "../../components/DataTable/ListingHeader";
import { MODULE_KEYS } from "../../api/api.keys";
import APP_CONFIG from "../../APP_CONFIG";
const COLUMNS = {
    PROFILE_PICTURE: "Profile Picture",
    FULL_NAME: "Full Name",
    EMAIL: "Email Address",
    ADDRESS: "Address",
    SKILL_LEVEL: "Skill Level"
};

const SORTABLE_COLUMNS = {
    [COLUMNS.FULL_NAME]: "FullName",
    [COLUMNS.EMAIL]: "Email",
    // [COLUMNS.ADDRESS]: "Address",
    [COLUMNS.SKILL_LEVEL]: "SkillLevel"
};

const Connections = (props) => {
    let { userId } = useParams();
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);

    const [filters, setFilters] = useState({
        [SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]]: null
    });

    const {
        isFetching,
        page,
        total,
        data,
        searchText,
        refetch,
        limit,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: MODULE_KEYS.USER_DETAILS_CONNECTIONS,
        requestFn: GetConnections,
        params: {
            UserId: userId,
            ...(currentSortColumn &&
                currentSortOrder && { Column: currentSortColumn, Direction: currentSortOrder }),
            ...(filters[SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]] && {
                [SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]]:
                    filters[SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]]
            })
        }
    });

    const handleSortClick = (item) => {
        setCurrentSortColumn(item.sortColumn);
        setCurrentSortOrder((prev) =>
            prev === SortOrder.ASC || !prev ? SortOrder.DESC : SortOrder.ASC
        );
    };

    return (
        <div className="mt-4">
            <Helmet>
                <title>User Management</title>
            </Helmet>
            <Container fluid>
                <ListingHeader
                    onSearchChange={handleSearchChange}
                    searchedText={searchText}
                    title={PageHeadings.USER_MANAGAMENT}
                />

                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    currentSortOrder={currentSortOrder}
                    currentSortColumn={currentSortColumn}
                    onSortClick={handleSortClick}
                    config={[
                        {
                            title: COLUMNS.PROFILE_PICTURE,
                            render: (data) => (
                                <UserAvatar
                                    imgSrc={
                                        data?.ProfileImage?.OriginalUrl ||
                                        APP_CONFIG.IMAGES.AVATAR_PLACEHOLDER
                                    }
                                    altText={"profile image"}
                                />
                            )
                        },

                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.FULL_NAME],
                            isSortable: true,
                            title: COLUMNS.FULL_NAME,
                            render: (data) => data?.FullName || "-"
                        },

                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.EMAIL],
                            isSortable: true,
                            title: COLUMNS.EMAIL,
                            render: (data) => data?.Email || "-"
                        },
                        {
                            // sortColumn: SORTABLE_COLUMNS[COLUMNS.ADDRESS],
                            // isSortable: true,
                            title: COLUMNS.ADDRESS,
                            render: (data) => data?.HomeCourse?.Address || "-"
                        },
                        {
                            title: COLUMNS.SKILL_LEVEL,
                            render: (data) => data?.SkillLevel || "-",
                            isFilterable: true,
                            filterConstants: SkillLevel,
                            filterValue: filters[SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]],
                            onFilterSelect: (value) =>
                                handleFilterChange(
                                    SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL],
                                    value,
                                    handlePageClick,
                                    setFilters
                                )
                        }
                    ]}
                />
                <Pagination
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                    rowLimit={limit}
                />
            </Container>
        </div>
    );
};

export default Connections;
