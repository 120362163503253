import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";

import RoundsSection from "./RoundsSection";
import AnalyticsSection from "./AnalyticsSection";
import GraphSection from "./GraphSection";
import { Heading } from "../../components/Heading";
import { PageHeadings } from "../../constants/PageHeading";
import { PageTitles } from "../../constants";

const Dashboard = () => {
    return (
        <>
            <div className="page-content">
                <Helmet>
                    <title>{PageTitles.DASHBOARD} </title>
                </Helmet>
                <Container fluid>
                    <Heading heading={PageHeadings.DASHBOARD} />
                    <AnalyticsSection />
                    <GraphSection />
                    <RoundsSection />
                </Container>
            </div>
        </>
    );
};

export default Dashboard;
