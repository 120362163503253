import React from "react";

const CustomText = ({
    className = "",
    imageUrl = "",
    titleClass = "",
    title = "Title",
    description = "Description",
    avatarSize = "md",
    avatar = false,
    hideLeft = false,
    borderRight = false,
    borderLeft = true,
    borderMargin = "mx-4"
}) => {
    return (
        <div
            className={`app-custom-text ${className} d-flex ${borderRight && "border-right"} ${
                borderLeft && "border-left"
            }`}
        >
            {!hideLeft && (
                <div className="">
                    {avatar ? (
                        imageUrl ? (
                            <img
                                src={imageUrl}
                                className={`avatar-${avatarSize} rounded-circle img-thumbnail`}
                            />
                        ) : (
                            <div
                                className={`avatar-${avatarSize} rounded-circle img-thumbnail d-flex align-items-center justify-content-center font-size-22 bg-theme-secondary fw-bold`}
                            >
                                {title.charAt(0)?.toUpperCase() || "#"}
                            </div>
                        )
                    ) : null}
                </div>
            )}
            <div
                className={`${
                    avatar ? "flex-grow-1" : `${borderMargin} center`
                } align-self-center ${
                    //mx-auto
                    !hideLeft && avatar ? "ms-3" : ""
                }`}
            >
                <div className="text-muted">
                    {title && (
                        <h5
                            className={`${titleClass} font-size-15 fw-bold ${
                                !description && "mb-0"
                            }`}
                        >
                            {title}
                        </h5>
                    )}
                    {description && (
                        <p className={`font-size-13 app-text-gray ${title ? "mb-1" : "mb-0"}`}>
                            {description}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CustomText;
