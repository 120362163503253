export const graphConfiguration = {
    chart: {
        toolbar: "false",
        dropShadow: {
            enabled: !0,
            color: "#000",
            top: 18,
            left: 7,
            blur: 8,
            opacity: 0.2
        }
    },
    dataLabels: {
        enabled: !1
    },
    colors: ["#BCBCBE","#2795AE"],
    stroke: {
        curve: "smooth",
        width: 3
    },
    yaxis: {
        showAlways: true,
        title: {
            text: "",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#ADB5BD",
                fontSize: "12px",
                fontFamily: "Poppins",
                fontWeight: 700,
                cssClass: "apexcharts-yaxis-title"
            }
        },
        labels: {
            formatter: function (val) {
                if(!val) return val
                return val.toFixed(0);
            }
        }
    },
    tooltip: {
        y: {
            formatter: function (val) {
                if(!val) return val
                return val.toFixed(0);
            }
        }
    },
    legend: {
        position: "top",
        horizontalAlign: "start",
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: 700,
        color: "#222",
        itemMargin: {
            horizontal: 10,
            vertical: 0
        }
    },
    noData: {
        text: "Data Not Found",
        align: "center",
        verticalAlign: "middle",
        
      }
};
