import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export const useRequestProcessor = () => {
    const queryClient = useQueryClient();

    // https://tanstack.com/query/v4/docs/react/reference/useQuery
    const useFetch = (
        keys = [],
        apiFunc = () => {},
        options = {},
       
    ) => {
        return useQuery({
            queryKey: keys,
            queryFn: apiFunc,
            retry: 2,
            refetchOnWindowFocus: false,
            ...options
        });
    };

    // https://tanstack.com/query/v4/docs/react/reference/useMutation
    const useMutate = (
        invalidationKey = "",
        mutationkey = "",
        apiFunc = () => {},
        onSuccess = () => {},
        onError = () => {},
        options = {}
    ) => {
        return useMutation({
            mutationKey: mutationkey,
            mutationFn: apiFunc,
            onSuccess: (resData) => {
                invalidationKey && queryClient.invalidateQueries(invalidationKey);
                onSuccess(resData);
            },
            onError: (errData) => {
                onError(errData);
            },

            ...options
        });
    };

    return { useFetch, useMutate };
};
