import React from "react";

import CustomModal from "./CustomModal";
import { CustomButton } from "../CustomButton";

const InformationModal = ({
    isOpen = false,
    Title= "",
    confirmButtonText = "",
    onConfirmClick = () => {},
    ...rest
}) => {

    return (
        <div>
            <CustomModal isOpen={isOpen} {...rest}>
                <div className="d-flex flex-column align-items-center pt-4 pb-0 px-5">
                    <div >
                        <h4 className="font-clr-theme-secondary fw-bold text-center mb-4">
                            {Title}
                        </h4>
                        {/* {Config.SubText && (
                            <p className="my-4 font-clr-theme-secondary">{Config.SubText}</p>
                        )} */}
                    </div>

                    <div className="d-flex justify-content-evenly w-75">
                        <CustomButton
                            title={confirmButtonText}
                            color="primary"
                            className="custom-btn-lg shadow-theme fw-bold"
                            onClick={onConfirmClick}
                        />
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default InformationModal;
