import { injectBaseConstantMethods } from "./BaseConstants";

const Roles = {
    Admin: "Admin",
    SuperAdmin: "Super Admin"
};

const displayTextKeys = {
    [Roles.Admin]: "Admin",
    [Roles.SuperAdmin]: "Super Admin"
};

const labelClass = {
    [Roles.Admin]: "admin",
    [Roles.SuperAdmin]: "super-admin"
};

export default injectBaseConstantMethods(Roles, displayTextKeys, labelClass);
