import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "reactstrap";

import suspendModalImg from "../../assets/images/app/common/modal-suspend-img.svg";
import CourseDataStructureUI from "./CourseDataStructureUI";
import { LABLES, NAMES } from "./CourseFieldsName";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { SwitchInput } from "../../components/Input";
import { ConfirmationModal } from "../../components/CustomModal";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { MODULE_KEYS } from "../../api/api.keys";
import { GetCourseDetail, UpdateCourseDetail } from "../../api/api.service";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { hasData } from "../../utils/commonUtils";
import { SUCCESS, showToast } from "../../utils/toastUtils";

let ACTIONS = {
    ACTIVATE: true,
    DEACTIVATE: false
};

let MODAL_CONTENT_CONFIG = {
    [ACTIONS.DEACTIVATE]: {
        modalTitle: "Are you sure you want to deactivate this course?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: suspendModalImg
    },
    [ACTIONS.ACTIVATE]: {
        modalTitle: "Are you sure you want to activate this course?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: suspendModalImg
    }
};

const DetailsCourse = ({}) => {
    let history = useHistory();
    let { courseId } = useParams();
    const [modalConfig, setModalConfig] = useState(null);
    let { useFetch, useMutate } = useRequestProcessor();

    let { data, isFetching } = useFetch(
        [MODULE_KEYS.DETAIL_COURSE, { courseId }],
        async ({ signal }) => await GetCourseDetail({ id: courseId }, signal)
    );
    const [courseStatus, setCoursetStatus] = useState(data?.Disabled);

    useEffect(() => {
        setCoursetStatus(data?.Disabled);
    }, [data]);

    let { mutate } = useMutate("", [MODULE_KEYS.COURSE_STATUS, { courseId }], (data) => {
        UpdateCourseDetail({ id: courseId, bodyData: data });
    });

    const handleActionInit = () => {
        let statusToSet = getStatus();
        setModalConfig(MODAL_CONTENT_CONFIG[statusToSet]);
    };

    const handleActionConfirmation = () => {
        const newStatus = getStatus();
        setCoursetStatus(!newStatus);
        const bodyData = {
            Disabled: !newStatus
        };
        mutate(bodyData, {
            onSuccess: async (res) => {
                showToast(SUCCESS, "Course has been updated successfully.");
            },
            onError: (err) => {}
        });
        setModalConfig(null);
    };

    const handleActionCancellation = () => {
        setModalConfig(null);
    };

    const getStatus = () =>
        courseStatus == ACTIONS.ACTIVATE ? ACTIONS.ACTIVATE : ACTIONS.DEACTIVATE;

    if (isFetching || !hasData(data)) {
        return (
            <Card>
                <LoaderFallbackUI
                    isLoader={isFetching}
                    className="loading-state-height card-shadow"
                />
            </Card>
        );
    }

    return (
        <>
            <div className="mt-3">
                <CourseDataStructureUI
                    isDetailView
                    FIELDS_TITLE={LABLES}
                    FIELDS_NAME={NAMES}
                    data={data}
                    onEdit={() =>
                        history.push(
                            getParamsAttachedRoute(APP_ROUTES.UPDATE_COURSE, {
                                courseId: courseId
                            })
                        )
                    }
                >
                    <div className="course-management-details-option-container me-3">
                        <SwitchInput
                            size="lg"
                            isChecked={!courseStatus}
                            onClick={handleActionInit}
                        />
                    </div>
                </CourseDataStructureUI>
            </div>
            <ConfirmationModal
                size="md"
                hasCustomConfig
                isOpen={modalConfig}
                customImageSrc={modalConfig?.imageSrc}
                customTitle={modalConfig?.modalTitle}
                customYesBtnText={modalConfig?.yesButtonText}
                customNoBtnText={modalConfig?.noButtonText}
                onNo={handleActionCancellation}
                onYes={handleActionConfirmation}
            />
        </>
    );
};

export default DetailsCourse;
