import React, { useState } from "react";
import { Container, Card } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";

import CourseDataStructureUI from "./CourseDataStructureUI";
import { LABLES, NAMES } from "./CourseFieldsName";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { UpdateCourseDetail, GetCourseDetail } from "../../api/api.service";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { hasData } from "../../utils/commonUtils";
import { ERROR, SUCCESS, showToast } from "../../utils/toastUtils";

const TOAST_SUCCESS = "Course updated successfully";
const DOLLAR_IN_PAYLOAD = "$";
const LAYOUT_IN_PAYLOAD = "N/A";
let MAP_ADDRESS_ENTITIES = {
    state: "administrative_area_level_1",
    city: "locality"
};

const { COURSE_PROFILE_LINK, STATE } = LABLES;
const UpdateCourse = () => {
    let history = useHistory();
    let { courseId: id } = useParams();

    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [fieldState, setFieldState] = useState(false);

    let { useFetch, useMutate } = useRequestProcessor();

    let { data, isFetching } = useFetch(
        [MODULE_KEYS.DETAIL_COURSE, { id }],
        async ({ signal }) => await GetCourseDetail({ id: id }, signal)
    );

    let { mutate, isLoading } = useMutate(
        "",
        getKey([MODULE_KEYS.UPDATE_COURSE], { id }),
        (data) => {
            UpdateCourseDetail({ id: id, bodyData: data });
        }
    );
    const updateCourseHandler = async (values) => {
        const {
            address,
            baskets,
            city,
            courseFee,
            courseName,
            notes,
            operationTimings,
            parking,
            teePads,
            state
        } = values;

        let fee = Number(courseFee);
        // let timings = operationTimings.split("-");

        const bodyData = {
            Name: courseName,
            State: state,
            City: city,
            Address: address,
            AddressLatitude: latitude,
            AddressLongitude: longitude,
            // Fee: fee,
            Currency: DOLLAR_IN_PAYLOAD,
            // Parking: parking,
            Baskets: baskets,
            TeePads: teePads,
            Layouts: LAYOUT_IN_PAYLOAD,
            Notes: notes,
            PdgaProfile: values[NAMES[COURSE_PROFILE_LINK]]
            // Timings: timings
        };

        mutate(bodyData, {
            onSuccess: (res) => createCourseSuccessHandler(),
            onError: (err) => createCourseErrorHandler(err)
        });
    };

    const createCourseSuccessHandler = () => {
        showToast(SUCCESS, TOAST_SUCCESS);
        handleUpdateCourse();
    };
    const createCourseErrorHandler = (error) => {};

    const handleUpdateCourse = () =>
        history.push(getParamsAttachedRoute(APP_ROUTES.COURSE_DETAILS, { courseId: id }));
    const handleCancel = () =>
        history.push(getParamsAttachedRoute(APP_ROUTES.COURSE_DETAILS, { courseId: id }));

    const handlePlaceSelect = (place, formik, addressField, cityField) => {
        const { lat, lng } = place?.geometry.location;
        setLatitude(lat());
        setLongitude(lng());
        if (place?.address_components.length) {
            const address_cmp = place.address_components;
            let city = "";
            let state = "";
            address_cmp.forEach((component) => {
                const { types, long_name, short_name } = component;
                if (types.includes(MAP_ADDRESS_ENTITIES.city)) {
                    city = long_name || short_name || "";
                }
                if (types.includes(MAP_ADDRESS_ENTITIES.state)) {
                    state = short_name || long_name || "";
                }
            });
            setFieldState(city);
            formik.setFieldValue(addressField, place?.formatted_address);
            formik.setFieldValue(cityField, city);
            formik.setFieldValue(NAMES[STATE], state);
        }
    };

    if (isFetching || !hasData(data)) {
        return (
            <Card>
                <LoaderFallbackUI
                    isLoader={isFetching}
                    className="loading-update-height card-shadow"
                />
            </Card>
        );
    }

    return (
        <>
            <Container className="page-content" fluid>
                <CourseDataStructureUI
                    isUpdate
                    FIELDS_TITLE={LABLES}
                    FIELDS_NAME={NAMES}
                    onCancel={handleCancel}
                    onSave={updateCourseHandler}
                    isLoading={isLoading}
                    data={data}
                    handlePlaceSelect={handlePlaceSelect}
                    fieldState={fieldState}
                />
            </Container>
        </>
    );
};

export default UpdateCourse;
