import { injectBaseConstantMethods } from "./BaseConstants";

const SkillLevel = {
    // Any: "Any",
    Beginner: "Beginner",
    Intermediate: "Intermediate",
    Advanced: "Advanced",
    Pro: "Pro"
};

const displayTextKeys = {
    // [SkillLevel.Any]: "Any",
    [SkillLevel.Advanced]: "Advanced",
    [SkillLevel.Intermediate]: "Intermediate",
    [SkillLevel.Beginner]: "Beginner",
    [SkillLevel.Pro]: "Pro"
};

const labelClass = {
    // [SkillLevel.Any]: "skill-any",
    [SkillLevel.Advanced]: "skill-advance",
    [SkillLevel.Intermediate]: "skill-intermediate",
    [SkillLevel.Beginner]: "skill-beginner",
    [SkillLevel.Pro]: "Pro"
};

export default injectBaseConstantMethods(SkillLevel, displayTextKeys, labelClass);
