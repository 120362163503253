import React from "react";

import { getMediaPath } from "../../utils/mediaUtils";
import APP_CONFIG from "../../APP_CONFIG";

import { CustomButton } from "../../components/CustomButton";

const ProfileHeader = ({
    img = "",
    skillLevel = "-",
    name = "-",
    onActionClick = () => {},
    config,
    buttonConfig,
    handleDelete = () => {}
}) => {
    return (
        <>
            <div className="profile-header-container bg-primary text-white d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center p-2">
                    <img
                        src={getMediaPath(img) || APP_CONFIG.IMAGES.AVATAR_PLACEHOLDER}
                        className="im-fluid rounded-circle"
                        width={100}
                        height={100}
                    />
                    <div className="d-flex flex-column p-2">
                        <div className="font-size-15">{name}</div>
                        <div className="font-size-13">Skill Level : {skillLevel}</div>
                    </div>
                </div>
                <div className="suspend-btn-conatiner">
                    <CustomButton
                        title={"Delete"}
                        className={`custom-btn-action-red me-3`}
                        onClick={handleDelete}
                        type="button"
                    />
                    <CustomButton
                        iconClassname="action-icon "
                        icon={buttonConfig?.btnIcon}
                        title={buttonConfig?.buttonTitle}
                        className={`${buttonConfig?.btnColor} `}
                        onClick={onActionClick}
                    />
                </div>
            </div>
        </>
    );
};

export default ProfileHeader;
