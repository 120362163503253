import { Col } from "reactstrap"

const DisplayProfileData  = (
  {
    title= '-',
    desc= "-",
    includeHR=true
  }
) => {
  return(
    <>
      <Col xs={4}>
          <strong className="clr-theme-primary">{title}</strong>
      </Col>
      <Col xs={8}>{desc}</Col>
      {includeHR && (<Col xs={12}>
          <hr />
      </Col>)}
    </>
  )
}

export default  DisplayProfileData