export const handleFilterChange = (
    filterKey = "",
    value = "",
    pageClickFunc = () => {},
    filterStateFunc = () => {}
) => {
    pageClickFunc(1);
    filterStateFunc((prev) => ({
        ...prev,
        [filterKey]: value
    }));
};
