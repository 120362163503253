import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";

const Loader = (props) => {
    const {
        classes = "",
        size ,
        color = "primary",
        showVerifyText = false,
        parentHeight = "none",
        parentClasses = ""
    } = props;
    return (
        <div className={parentClasses} style={{ height: parentHeight }}>
            <div
                className={`d-flex flex-column justify-content-center align-items-center w-100 ${classes}`}
            >
                <Spinner size={size} color={color} />
                {showVerifyText && <p className="pt-3">Verifying</p>}
            </div>
        </div>
    );
};

Loader.propTypes = {
    classes: PropTypes.any,
    size: PropTypes.any,
    color: PropTypes.any,
    showVerifyText: PropTypes.any,
    parentHeight: PropTypes.string,
    parentClasses: PropTypes.string
};

export default Loader;
