import { Get, Put, Post, Delete, Patch } from "./api.helper";
import { store } from "../store";
import { handleFetchError } from "../utils/errorHandler";
import Config from "../Config";
import { appendQueryParams } from "../utils/urlUtils";

// ==== Authentication
export const Login = (bodyData) => {
    return Post({
        url: "/auth/login",
        body: bodyData,
        isAuthorized: false
    });
};

export const GetMe = () => {
    return Get({ url: "/user/me" });
};

export const ForgotPassword = (bodyData) => {
    return Post({ url: "/auth/forget-password", body: bodyData });
};

export const ForgotPasswordVerification = (bodyData) => {
    return Post({
        url: "/auth/forget-password/verify-code",
        body: bodyData
    });
};

export const ResetPassword = (bodyData) => {
    return Post({ url: "/auth/reset-password", body: bodyData });
};

export const VerifyUser = (bodyData) => {
    return Post({
        url: "/",
        body: bodyData,
        isAuthorized: false
    });
};

export const SignUp = (bodyData) => {
    return Post({
        url: "/auth/signup",
        body: bodyData,
        isAuthorized: false
    });
};

// User Management

export const GetUsers = ({ params = {}, abortSignal = null }) => {
    return Get({ url: "/user", params, abortSignal });
};

export const GetUserById = (Id, params = {}, abortSignal = null) => {
    return Get({ url: `/user/${Id}`, params, abortSignal });
};

export const GetConnections = ({ params = {}, abortSignal = null }) => {
    return Get({ url: "/connection", params, abortSignal });
};

export const UpdateUser = ({ id, bodyData }) => {
    return Put({ url: `/user/customer/${id}`, body: bodyData });
};

export const UpdateUsersData = ( bodyData ) => {
    return Post({url: `/user/update-csv`, body: bodyData})
};

export const DeleteUserById = (Id) => {
    return Delete({ url: `/user/customer/${Id}` });
};

// ROUNDS APIS
export const GetRounds = ({ params = {}, abortSignal = null }) => {
    return Get({ url: `/round`, params, abortSignal });
};

// Content Management
export const GetArticles = ({ params = {}, abortSignal = null }) => {
    return Get({ url: "/article", params, abortSignal });
};

export const GetArticleById = (Id, params = {}, abortSignal = null) => {
    return Get({ url: `/article/${Id}`, params, abortSignal });
};

export const CreateArticle = (bodyData) => {
    return Post({ url: "/article", body: bodyData });
};

export const UpdateArticleById = (Id, bodyData) => {
    return Put({ url: `/article/${Id}`, body: bodyData });
};

export const DeleteArticleById = (Id) => {
    return Delete({ url: `/article/${Id}` });
};

export const MoveArticleToTop = (Id) => {
    return Post({ url: `/article/${Id}/reorder` });
};

// Course Management
export const GetCourses = ({ params = {}, abortSignal = null }) => {
    return Get({ url: "/course", params, abortSignal });
};

// export const ImportCourses = (bodyData) => {
//     console.log('bodyData', bodyData)
//     return Post({
//         url: "/course/upload-csv",
//         body: bodyData
//     });
// };

export const ImportCourses = async (bodyData) => {
    const token = store.getState().userAuth.user.Token;

    const url = `${Config.env().API_URL}/course/upload-csv`;

    return fetch(url, {
        headers: {
            authorization: token
            // "Content-Type": "multipart/form-data"
        },
        // responseType: "blob",
        method: "POST",
        body: bodyData
    })
        .then(handleFetchError)
        .catch((err) => {});
};

// ADD COURSE
export const AddingCourse = (bodyData) => {
    return Post({ url: "/course", body: bodyData });
};

//DETAIL COURSE
export const GetCourseDetail = ({ id = "", params = {}, abortSignal = null }) => {
    return Get({ url: `/course/${id}`, params, abortSignal });
};

//EDIT COURSE
export const UpdateCourseDetail = ({ id = "", bodyData }) => {
    return Put({ url: `/course/${id}`, body: bodyData });
};

// Ad Management
export const GetAds = ({ params = {}, signal = null }) => {
    return Get({ url: "/advertisement", params, signal });
};

export const CreateAdvertisement = ({ bodyData }) => {
    return Post({ url: "/advertisement", body: bodyData });
};

export const GetAdvertisementById = ({ id, params = {}, signal = null }) => {
    return Get({ url: `/advertisement/${id}`, params, signal });
};

export const UpdateAdvertisementById = ({ id, bodyData }) => {
    return Put({ url: `/advertisement/${id}`, body: bodyData });
};

// ALPHA LISTING API
export const ListingApi = (params = {}, abortSignal = null) => {
    return Get({ url: "/user", params, abortSignal });
};

// Account

export const ChangePasswordAccount = (bodyData) => {
    return Post({ url: "/auth/change-password", body: bodyData });
};

export const UpdateProfileAPI = (bodyData) => {
    return Patch({ url: "/user", body: bodyData });
};

export const MediaUpload = (bodyData, abortSignal = null) => {
    const token = store.getState().userAuth.user.Token;

    const url = `${Config.env().API_URL}/media/upload`;

    return fetch(url, {
        headers: {
            authorization: token
        },
        body: bodyData,
        method: "POST"
    }).then(handleFetchError);
};

// DASHBOARD APIS

export const GetDashboardAnalytics = (params, abortSignal = null) => {
    return Get({
        url: "/analytics/dashboard",
        params,
        abortSignal
    });
};

export const GetGraphData = (params, abortSignal = null) => {
    return Get({
        url: "/analytics/users",
        params,
        abortSignal
    });
};

// ROUNDS APIS
export const GetAllRounds = (params, abortSignal = null) => {
    return Get({
        url: "/round",
        params,
        abortSignal
    });
};

//========================
// Examples of Api Calling
//========================

// GET

// const GetApiExample = (params = {}, abortSignal = null) => {
//     return Get({ url: "/url", params, abortSignal });
// };

// // GET BY ID

// const GetByIdApiExample = (id) => {
//     return Get({ url: `/url/${id}` });
// };

// // POST
// const PostApiExample = (bodyData) => {
//     return Post({
//         url: "/url",
//         body: bodyData
//     });
// };
// // POST BY ID

// const PostByIdApiExample = ({ id, bodyData }) => {
//     return Post({
//         url: `/url${id}`,
//         body: bodyData
//     });
// };
// // PUT

// const PutApiExample = (bodyData) => {
//     return Put({
//         url: "/url",
//         body: bodyData
//     });
// };
// // PUT BY ID

// const PutByIdApiExample = ({ id, bodyData }) => {
//     return Put({
//         url: `/url${id}`,
//         body: bodyData
//     });
// };
// //  DELETE
// const DeleteApiExample = (id) => {
//     return Delete({
//         url: `/url/${id}`
//     });
// };
