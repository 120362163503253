import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Label } from "reactstrap";
import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { loginFormValidationSchema, initialValues } from "./FormValidations";
import { TextInput, PasswordInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { login } from "../../store/actions";
import AuthWrapper from "./AuthWrapper";
import PageTitles from "../../constants/PageTitles";
import Headings from "./Headings";
import { SUCCESS, showToast } from "../../utils/toastUtils";

const Login = ({}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: initialValues.loginForm,
        validationSchema: loginFormValidationSchema,
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleFormSubmit = async (values) => {
        const { email, password } = values;
        try {
            setIsLoading(true);
            let res = await dispatch(
                login({
                    Email: email,
                    Password: password
                })
            );
            showToast(SUCCESS, "Login successfully !");
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.LOGIN}</title>
            </Helmet>
            <AuthWrapper>
                <div>
                    <Headings title="WELCOME" subText="Let's Get Started" />
                    <form className="mt-5" onSubmit={formik.handleSubmit}>
                        <Label>Email</Label>
                        <div className="mb-4">
                            <TextInput
                                placeholder="Enter Email"
                                name={"email"}
                                type="email"
                                className="hide-default-icon"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                autoFocus
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <span className="error">{formik.errors.email}</span>
                            ) : null}
                        </div>

                        <Label>Password</Label>
                        <div className="mb-4">
                            <PasswordInput
                                placeholder="Enter Password"
                                className="hide-default-icon"
                                name="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <span className="error">{formik.errors.password}</span>
                            ) : null}
                        </div>
                        <div>
                            <Link
                                className="d-flex align-items-center justify-content-end my-4"
                                to={APP_ROUTES.FORGET_PASSWORD}
                            >
                                <p className="font-clr-theme-secondary"> Forgot Password ? </p>
                            </Link>
                        </div>

                        <CustomButton
                            loading={isLoading}
                            type="submit"
                            color="primary"
                            title="LOGIN"
                            className="w-100"
                        />
                    </form>
                </div>
            </AuthWrapper>
        </>
    );
};

export default withRouter(Login);
