import React from "react";
import { useParams } from "react-router-dom";

import { RoutingTabs } from "../../components/RoutingTabs";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import DetailsCourse from "./Details_Course";
import DetailsRounds from "./Details_Rounds";

const Details = ({}) => {
    let { courseId } = useParams();
    let CONFIG = {
        param: { courseId },
        tabsConfig: [
            {
                title: "Course Details",
                id: "1",
                defaultActive: true,
                path: APP_ROUTES.COURSE_DETAILS,
                component: <DetailsCourse />
            },
            {
                title: "Rounds",
                id: "2",
                defaultActive: false,
                path: APP_ROUTES.COURSE_DETAILS_ROUNDS,
                component: <DetailsRounds />
            }
        ]
    };

    return (
        <div className="page-content">
            <RoutingTabs
                config={CONFIG}
                isPills
                isTabs={false}
                parentRoute={APP_ROUTES.COURSE_MANAGEMENT_DETAILS}
            />
        </div>
    );
};

export default Details;
