import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const SwitchInput = ({
    switchDisable = false,
    allDisable = false,
    label = "",
    isChecked = false,
    size = "",
    onClick = () => {}
}) => {
    return (
        <FormGroup
            switch
            disabled={allDisable}
            className="d-flex align-items-center justify-content-center"
        >
            <Input
                type="switch"
                role="switch"
                disabled={switchDisable}
                checked={isChecked}
                onClick={onClick}
                className={`app-switch-input cursor-pointer ${size}`}
            />
            {label && <Label>{label}</Label>}
        </FormGroup>
    );
};

export default SwitchInput;
