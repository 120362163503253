import toast from "react-hot-toast";

export const SUCCESS = "success";
export const ERROR = "error";
const PROMISE = "promise";

export const showToast = (type = "", message = "", durations = 4000, options = {}) => {
    const toastOptions = { duration: durations, ...options };
    switch (type) {
        case SUCCESS:
            return toast.success(message, toastOptions);
        case ERROR:
            return toast.error(message, toastOptions);
        case PROMISE:
            return toast.promise(
                new Promise((resolve, reject) => {
                    resolve();
                }),
                toastOptions
            );
        case type:
            return toast(message, toastOptions);
        default:
            return;
    }
};
