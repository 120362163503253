import * as Yup from "yup";
import { LABLES, NAMES } from "./AccountFieldsNames";
let { PASSWORD, NEW_PASSWORD, CONFIRM_PASSWORD, EMAIL, FULL_NAME } = LABLES;

export const initialValues = {
    changePassword: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    },
    profileUpdate: {
        email: "",
        fullName: ""
    }
};

export const resetPassFormValidationSchema = Yup.object({
    [NAMES[PASSWORD]]: Yup.string().required("Enter Password"),
    [NAMES[NEW_PASSWORD]]: Yup.string()
        .required("Enter New Password")
        .notOneOf([Yup.ref("oldPassword")], "Old Password and New Password shouldn't be simillar")
        .min(8, "Password should be minimum 8 characters long")
        .max(25, "Password should be between 8 to 25 characters")
        .test(
            "regex",
            "Password must contains characters, capital letters, special characters and numbers",
            (val) => {
                let regExp = new RegExp("^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$");

                return regExp.test(val);
            }
        ),
    [NAMES[CONFIRM_PASSWORD]]: Yup.string()
        .required("Enter Confirm Password")
        .oneOf([Yup.ref("newPassword")], "Please make sure your passwords match")
});

export const updateProfileValidationSchema = Yup.object({
    [NAMES[EMAIL]]: Yup.string()
        .email("Enter a valid email address")
        .required("Enter email address")
        .min(6, "Too short email!")
        .max(50, "Too long email!")
        .matches(
            /([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/,
            "Enter valid email address"
        ),
    [NAMES[FULL_NAME]]: Yup.string()
        .required("Enter FullName")
        .min(6, "Too short FullName")
        .max(50, "Too long FullName")
});
