import { AdStatus } from "../../constants";
import suspendModalImg from "../../assets/images/app/common/modal-suspend-img.svg";

export const MODAL_CONTENT_CONFIG = {
  [AdStatus.INACTIVE]: {
      modalTitle: "Are you sure you want to deactivate this ad?",
      yesButtonText: "Yes",
      noButtonText: "Cancel",
      imageSrc: suspendModalImg
  },
  [AdStatus.ACTIVE]: {
      modalTitle: "Are you sure you want to activate this ad?",
      yesButtonText: "Yes",
      noButtonText: "Cancel",
      imageSrc: suspendModalImg
  }
};