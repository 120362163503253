import React from "react";
import APP_CONFIG from "../APP_CONFIG";

export default ({ component: Component, title = "" }) => {
    const withTitle = (props) => {
        document.title = `${title} - ${APP_CONFIG.APP_NAME}`;
        return <Component {...props} />;
    };

    return withTitle;
};
