import React from "react";
import { Media } from "reactstrap";

const UserAvatar = ({ imgSrc, altText, fullName }) => {
    return (
        <Media>
            <Media left href="#">
                <Media
                    object
                    src={imgSrc}
                    alt={altText}
                    style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                />
            </Media>
            <Media body>
                <Media heading>{fullName}</Media>
                {/* Additional user information can be displayed here */}
            </Media>
        </Media>
    );
};

export default UserAvatar;
