import { injectBaseConstantMethods } from "./BaseConstants";

const SkillLevelArticle = {
    // Any: "Any",
    Beginner: "Beginner",
    Intermediate: "Intermediate",
    Advanced: "Advanced",
};

const displayTextKeys = {
    // [SkillLevelArticle.Any]: "Any",
    [SkillLevelArticle.Advanced]: "Advanced/Pro",
    [SkillLevelArticle.Intermediate]: "Intermediate",
    [SkillLevelArticle.Beginner]: "Beginner",
};

const labelClass = {
    // [SkillLevelArticle.Any]: "skill-any",
    [SkillLevelArticle.Advanced]: "skill-advance",
    [SkillLevelArticle.Intermediate]: "skill-intermediate",
    [SkillLevelArticle.Beginner]: "skill-beginner",
};

export default injectBaseConstantMethods(SkillLevelArticle, displayTextKeys, labelClass);
