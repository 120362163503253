import { injectBaseConstantMethods } from "./BaseConstants";

const RoundStatus = {
    Scheduled: "Scheduled",
    Playing: "Playing",
    Completed: "Completed",
    Canceled: "Canceled"
};

const displayTextKeys = {
    [RoundStatus.Scheduled]: "Scheduled",
    [RoundStatus.Playing]: "Playing",
    [RoundStatus.Completed]: "Completed",
    [RoundStatus.Canceled]: "Canceled"
};

const labelClass = {
    [RoundStatus.Scheduled]: "round-scheduled",
    [RoundStatus.Playing]: "round-playing",
    [RoundStatus.Completed]: "round-completed",
    [RoundStatus.Canceled]: "round-canceled"
};

export default injectBaseConstantMethods(RoundStatus, displayTextKeys, labelClass);
