import React from "react";
import { TextInput } from "../Input";
import { CustomText } from "../CustomText";

let EDIT_ICON = <span className="bx bx-edit-alt icon-grey"></span>;

const EditableDetail = ({
    isUpdate = false,
    isDetails = false,
    title = "",
    name = "",
    value = "",
    inputClasses = "",
    onChange = () => {},
    ...rest
}) => {
    return (
        <>
            {isUpdate && (
                <TextInput
                    inputClasses={inputClasses}
                    icon={EDIT_ICON}
                    label={title}
                    name={name}
                    value={value}
                    onChange={onChange}
                    {...rest}
                />
            )}
            {isDetails && <CustomText title={title} description={value || "-"} />}
        </>
    );
};

export default EditableDetail;
