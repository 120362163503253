import React from "react";
import { Row, Col } from "reactstrap";
import DisplayProfileData from "./DisplayProfileData";
import {RoundType } from "../../constants";

const HEADINGS = {
    EMAIL: "Email Address:",
    PDGA_RATING: "PDGA Rating:",
    ADDRESS: "Address:",
    HOME_COURSE: "Home Course:",
    GOALS: "Goals:",
    BEST_DAYS: "Best Days:",
    PRIMARY_CLUB: "Primary Club:",
    BEST_TIME: "Best Time:",
    DOMINANT_THROW: "Dominant Throw:",
    ROUND_PER_WEEK: "Round Per Week:",
    FAVOURITE_DISC: "Favorite Disc:",
    PREFFERED_ROUNDS: "Preferred Rounds:",
    ZIP_CODE: "Zip Code:",
    PDGA_NUMBER: "PDGA Number:",
    PLAYING_SINCE: "Playing Since:"
};
const ProfileContent = (props) => {
    let {
        email = "-",
        address = "-",
        pdgaRating = "-",
        homeCourse = "-",
        primaryClub = "-",
        bestDays = "-",
        bestTime = "-",
        dominantThrow = "-",
        favouriteDisc = "-",
        roundPerWeek = "-",
        referredRounds = "-",
        goals = "-",
        zipcode = "-",
        playingSince = "-",
        pdgaNumber = "-"
    } = props;
    return (
        <div style={{ color: "black" }} className="p-3">
            <Row>
                <Col md={6}>
                    <Row>
                        <DisplayProfileData title={HEADINGS.EMAIL} desc={email} />
                        <DisplayProfileData title={HEADINGS.PDGA_RATING} desc={pdgaRating} />
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <DisplayProfileData title={HEADINGS.ADDRESS} desc={address} />
                        <DisplayProfileData title={HEADINGS.HOME_COURSE} desc={homeCourse} />
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <DisplayProfileData title={HEADINGS.PDGA_NUMBER} desc={pdgaNumber} />
                        <DisplayProfileData title={HEADINGS.ZIP_CODE} desc={zipcode} />
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <DisplayProfileData title={HEADINGS.ROUND_PER_WEEK} desc={roundPerWeek} />

                        <DisplayProfileData title={HEADINGS.BEST_TIME} desc={bestTime} />
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <DisplayProfileData title={HEADINGS.FAVOURITE_DISC} desc={favouriteDisc} />
                        <DisplayProfileData title={HEADINGS.DOMINANT_THROW} desc={dominantThrow} />
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <DisplayProfileData title={HEADINGS.BEST_DAYS} desc={bestDays} />
                        <DisplayProfileData
                            title={HEADINGS.PREFFERED_ROUNDS}
                            desc={RoundType.getDisplayTextKey(referredRounds)}
                        />
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <DisplayProfileData title={HEADINGS.PLAYING_SINCE} desc={playingSince} includeHR={false}/>
                    </Row>
                </Col>
                <Col md={6} className="pb-3">
                    <Row>
                        <DisplayProfileData title={HEADINGS.GOALS} desc={goals} includeHR={false}/>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default ProfileContent;
