import { Loader } from "../Loader";
import { NoData } from "../NoData";

const LoaderFallbackUI = ({ className = "", isLoader = false, hasData = false }) => {
    return (
        <>
            <div className={`w-100 d-flex justify-content-center align-items-center ${className}`}>
                {isLoader ? <Loader /> : !hasData && <NoData />}
            </div>
        </>
    );
};

export default LoaderFallbackUI;
