import { injectBaseConstantMethods } from "./BaseConstants";

const RoundType = {
    Practice: "Practice",
    Casual: "Casual",
    Club: "Club"
};

const displayTextKeys = {
    [RoundType.Practice]: "Practice",
    [RoundType.Casual]: "Regular",
    [RoundType.Club]: "Club"
};

const labelClass = {
    [RoundType.Practice]: "round-practice",
    [RoundType.Casual]: "round-casual",
    [RoundType.Club]: "round-club"
};

export default injectBaseConstantMethods(RoundType, displayTextKeys, labelClass);
