import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

import { routeConfigs } from "./RouteConfigs";
import logo from "../../assets/images/audit-icon.png";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";

import "./Breadcrumb.scss";

const Breadcrumb = (props) => {
    const { title, breadcrumbItems } = props;
    // const itemLength = breadcrumbItems.length;

    const matches = useRouteMatch();

    const { pathname } = useLocation();

    let pathNameArr = pathname?.split("/");

    const routeConfigs = [
        {
            path: APP_ROUTES.DASHBOARD,
            title: "DASHBOARD",
            count: "1",
            matchUrl: "/dashboard",
            key: "01"
        },
        {
            path: APP_ROUTES.USER_MANAGEMENT,
            title: "USER MANAGEMENT",
            count: "1",
            matchUrl: "/users",
            key: "02"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.USER_MANAGEMENT_DETAILS, {
                userId: matches?.params?.userId
            }),
            title: "DETAILS",
            count: "2",
            matchUrl: "/user/:userId",
            key: "03"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.USER_MANAGEMENT_PROFILE, {
                userId: matches?.params?.userId
            }),
            title: "PROFILE",
            count: "2",
            matchUrl: "/users/:userId/profile",
            key: "04"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.USER_MANAGEMENT_CONNECTIONS, {
                userId: matches?.params?.userId
            }),
            title: "CONNECTIONS",
            count: "2",
            matchUrl: "/users/:userId/connections",
            key: "05"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.USER_MANAGEMENT_ROUNDS, {
                userId: matches?.params?.userId
            }),
            title: "ROUNDS",
            count: "2",
            matchUrl: "/users/:userId/rounds",
            key: "06"
        },

        {
            path: APP_ROUTES.CONTENT_MANAGEMENT,
            title: "ARTICLE MANAGEMENT",
            count: "1",
            matchUrl: "/contents",
            key: "07"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.CONTENT_MANAGEMENT_DETAILS, {
                contentId: matches?.params?.contentId
            }),
            title: "DETAILS",
            count: "2",
            matchUrl: "/contents/:contentId",
            key: "08"
        },

        {
            path: getParamsAttachedRoute(APP_ROUTES.CONTENT_MANAGEMENT_UPDATE, {
                contentId: matches?.params?.contentId
            }),
            title: "UPDATE",
            count: "2",
            matchUrl: "/contents/update/:contentId",
            key: "09"
        },

        {
            path: APP_ROUTES.COURSE_MANAGEMENT,
            title: "COURSE MANAGEMENT",
            count: "1",
            matchUrl: "/courses",
            key: "10"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.COURSE_DETAILS, {
                courseId: matches?.params?.courseId
            }),
            title: "DETAILS",
            count: "2",
            matchUrl: "/courses/:courseId",
            key: "11"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.COURSE_DETAILS_ROUNDS, {
                courseId: matches?.params?.courseId
            }),
            title: "ROUNDS",
            count: "2",
            matchUrl: "/courses/:courseId/rounds",
            key: "12"
        },

        {
            path: getParamsAttachedRoute(APP_ROUTES.UPDATE_COURSE, {
                courseId: matches?.params?.courseId
            }),
            title: "UPDATE",
            count: "2",
            matchUrl: "/courses/update/:courseId",
            key: "13"
        },

        {
            path: APP_ROUTES.AD_MANAGEMENT,
            title: "AD MANAGEMENT",
            count: "1",
            matchUrl: "/ads",
            key: "13"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.AD_DETAILS, {
                adId: matches?.params?.adId
            }),
            title: "DETAILS",
            count: "2",
            matchUrl: "/ads/:adId",
            key: "14"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.UPDATE_AD, {
                adId: matches?.params?.adId
            }),
            title: "UPDATE",
            count: "2",
            matchUrl: "/ads/update/:adId",
            key: "15"
        },
        {
            path: APP_ROUTES.ACCOUNT,
            title: "ACCOUNT",
            count: "1",
            matchUrl: "/account",
            key: "16"
        },
        {
            path: APP_ROUTES.ACCOUNT_PROFILE,
            title: " PROFILE",
            count: "2",
            matchUrl: "/account/profile",
            key: "17"
        },
        {
            path: APP_ROUTES.ACCOUNT_CHANGE_PASSWORD,
            title: " CHANGE PASSWORD",
            count: "2",
            matchUrl: "/account/update",
            key: "18"
        },
        {
            path: APP_ROUTES.UPDATE_ACCOUNT_PROFILE,
            title: "UPDATE",
            count: "2",
            matchUrl: "/account/profile/update",
            key: "19"
        }
    ];

    return (
        // <Row>
        //     <Col xs="12">
        //         <div className="page-title-box d-flex align-items-center justify-content-between">
        //             <h4 className="mb-0 font-size-18">{title}</h4>
        //             <div className="page-title-right">
        //                 <ol className="breadcrumb m-0">
        //                     {breadcrumbItems.map((item, key) => (
        //                         <BreadcrumbItem key={key} active={key + 1 === itemLength}>
        //                             <Link to="#">{item.title}</Link>
        //                         </BreadcrumbItem>
        //                     ))}
        //                 </ol>
        //             </div>
        //         </div>
        //     </Col>
        // </Row>

        <Row className="breadcrumbs-con">
            <Col xs="12" className="breadcrumbs-col">
                <div className="d-flex align-items-center justify-content-between">
                    {/* <h4 className="mb-0 font-size-18">{title}</h4> */}
                    <div className="page-title-right d-flex">
                        {routeConfigs.map((el, idx) => {
                            if (pathname.startsWith(el.path)) {
                                if (el.count == "2") {
                                    return (
                                        <div key={el.key} className="d-flex align-items-center">
                                            &nbsp; <span> / </span> &nbsp;
                                            <div>
                                                <div
                                                    className="breadcrumb-typo breadcrumb-typo-bold"
                                                    to={el.path}
                                                >
                                                    {el.title}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={el.key}>
                                            {pathNameArr.length > 2 ? (
                                                <div>
                                                    <Link className="breadcrumb-typo" to={el.path}>
                                                        {el.title}
                                                    </Link>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div
                                                        className="breadcrumb-typo breadcrumb-typo-bold"
                                                        to={el.path}
                                                    >
                                                        {el.title}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                }
                            } else {
                                if (pathname == el.path) {
                                }
                            }
                        })}

                        {/* <ol className="breadcrumb m-0"> */}

                        {/* {breadcrumbItems.map((item, key) => (
                <BreadcrumbItem key={key} active={key + 1 === itemLength}>
                  <Link to="#">{item.title}</Link>
                </BreadcrumbItem>
              ))} */}
                        {/* </ol> */}
                    </div>
                </div>
            </Col>
        </Row>
    );
};

Breadcrumb.propTypes = {
    breadcrumbItems: PropTypes.array,
    title: PropTypes.string
};

export default Breadcrumb;
