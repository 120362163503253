import React from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import { Card, CardBody, Col, Row, Container } from "reactstrap";

import { CustomButton } from "../../components/CustomButton";
import { updateProfileValidationSchema } from "./FormValidations";
import { ImageBox } from "../../components/ImageBox";
import { EditableDetail } from "../../components/EditableDetail";
import { PageTitles } from "../../constants";
import APP_CONFIG from "../../APP_CONFIG";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";

const Profile = ({
    isUpdate = false,
    isDetailView = false,
    FIELDS_TITLE = {},
    FIELDS_NAME = {},
    isButtonLoading = false,
    onCancel = () => {},
    onEdit = () => {},
    onSubmitHandling = () => {},
    onImageUpload = () => {}
}) => {
    const { FullName, Email, ProfileImage } = useSelector((store) => store?.userAuth?.user);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { fullName: FullName, email: Email },
        validationSchema: updateProfileValidationSchema,
        onSubmit: (values) => {
            onSubmitHandling(values);
        }
    });

    let { FULL_NAME, EMAIL } = FIELDS_TITLE;

    return (
        <div className=" admin-profile">
            <Helmet>
                <title>{PageTitles.ACCOUNT}</title>
            </Helmet>

            <Card className="custom-card mt-3">
                <form onSubmit={formik.handleSubmit}>
                    <Container fluid>
                        <CardBody className="account-responsiveness">
                            <Row className="d-flex align-items-center">
                                <Col sm="12" md="3" lg="3">
                                    <ImageBox
                                        containerClasses="w-100 h-100"
                                        isEditable={isUpdate}
                                        imageClasses="rounded-circle profile-image"
                                        editClasses="profile-edit-icon"
                                        onImageUpload={onImageUpload}
                                        imgUrl={ProfileImage?.OriginalUrl}
                                        placeholderImg={APP_CONFIG.IMAGES.AVATAR_PLACEHOLDER}
                                    />
                                </Col>
                                <Col sm="12" md="4" lg="4" className="margin-responsive editable-field-con">
                                    <EditableDetail
                                        isUpdate={isUpdate}
                                        isDetails={isDetailView}
                                        title={FULL_NAME}
                                        name={FIELDS_NAME[FULL_NAME]}
                                        value={formik.values[FIELDS_NAME[FULL_NAME]]}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <FormikErrorText
                                    formikInstance = {formik}
                                    fieldName = {FIELDS_NAME[FULL_NAME]}
                                     />
                                </Col>
                                <Col sm="12" md="4" lg="4" className="margin-responsive editable-field-con">
                                        <EditableDetail
                                            isUpdate={isUpdate}
                                            isDetails={isDetailView}
                                            title={EMAIL}
                                            name={FIELDS_NAME[EMAIL]}
                                            value={formik.values[FIELDS_NAME[EMAIL]]}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <FormikErrorText
                                        formikInstance = {formik}
                                        fieldName = {FIELDS_NAME[EMAIL]}
                                        />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col sm="12" md="12" lg="12">
                                    {isDetailView && (
                                        <CustomButton
                                            title="Edit"
                                            color="primary"
                                            className="custom-btn-lg"
                                            onClick={onEdit}
                                        />
                                    )}
                                    {isUpdate && (
                                        <div className="account-btns-responsiveness mt-3 ">
                                            <CustomButton
                                                type={"Submit"}
                                                title="Save Changes"
                                                color="primary"
                                                className="custom-btn-lg"
                                                loading={isButtonLoading}
                                            />
                                            <CustomButton
                                                title="Cancel"
                                                outline={true}
                                                color="primary"
                                                className="custom-btn-lg-secondary mx-md-2 mx-lg-2"
                                                onClick={onCancel}
                                            />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Container>
                </form>
            </Card>
        </div>
    );
};

export default Profile;
