import React, { useState } from "react";
import { ImageTypes } from "../../constants";
import { imageUploadHandler } from "../../utils/mediaUtils";

const ImageUploader = ({
    imageUploadText = "Click to upload",
    label = "",
    setContentImage = () => {},
    imageValidtion = false
}) => {
    const [image, setImage] = useState(null);
    const [imageError, setImageError] = useState("");
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            imageUploadHandler({
                file,
                imagePreviewCB: setImage,
                imageErrorCB: setImageError,
                uploadImgCB: setContentImage,
                fileTypes: ImageTypes
            });
        }
    };

    const handleImageContainerClick = () => {
        // Trigger the hidden input click event
        document.getElementById("fileInput").click();
    };

    return (
        <>
            <div className="d-flex flex-column">
                <p className="mb-1 clr-theme-primary fw-bold">{label}</p>
                <div
                    className="image-uploader-container d-flex justify-content-center align-items-center cursor-pointer"
                    onClick={handleImageContainerClick}
                >
                    {image ? (
                        <img src={image} alt="Uploaded" className="uploaded-image" />
                    ) : (
                        <div className="d-flex align-items-center flex-column">
                            <span className="bx bxs-cloud-upload bx-lg clr-theme-primary"></span>
                            <h6 className="fw-bold">{imageUploadText}</h6>
                        </div>
                    )}
                </div>
                <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                />
            </div>
            <span className="text-danger">{imageError || imageValidtion}</span>
        </>
    );
};

export default ImageUploader;
