import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";

import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import ListingHeader from "../../components/DataTable/ListingHeader";
import { GetRounds } from "../../api/api.service";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { monthYear } from "../../utils/dateUtils";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import {
    PageHeadings,
    SortOrder,
    RoundsStatus,
    RoundType,
    PageTitles,
    SkillLevel
} from "../../constants";
import { handleFilterChange } from "../../utils/tableUtils";
import { convertDateTime, dateTimeFormat } from "../../utils/dateFormatUtils";
import { getTwoStrConcat } from "../../utils/commonUtils";

const COLUMNS = {
    ROUND_NAME: "Round Name",
    COURSE_NAME: "Course Name",
    ADDRESS: "Address",
    CITY: "City",
    ROUND_TYPE: "Round Type",
    DATE: "Date/Time",
    SKILL_LEVEL: "Skill Level",
    STATUS: "Status"
};

const SORTABLE_COLUMNS = {
    [COLUMNS.ROUND_NAME]: "",
    [COLUMNS.ADDRESS]: "",
    [COLUMNS.CITY]: "",
    [COLUMNS.ROUND_TYPE]: "Type",
    [COLUMNS.DATE]: "",
    [COLUMNS.SKILL_LEVEL]: "SkillLevel",
    [COLUMNS.STATUS]: "Status"
};

const DetailsRounds = ({}) => {
    const history = useHistory();
    let { courseId } = useParams();

    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);

    const [filters, setFilters] = useState({
        [SORTABLE_COLUMNS[COLUMNS.ROUND_TYPE]]: null,
        [SORTABLE_COLUMNS[COLUMNS.STATUS]]: null,
        [SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]]: null
    });

    const {
        isFetching,
        page,
        total,
        data,
        searchText,
        refetch,
        limit,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.COURSE_MANAGEMENT_ROUNDS_LISTING], { courseId }),
        requestFn: GetRounds,
        params: {
            ...(courseId && { CourseId: courseId }),
            ...(currentSortColumn &&
                currentSortOrder && { Column: currentSortColumn, Direction: currentSortOrder }),
            ...(filters[SORTABLE_COLUMNS[COLUMNS.ROUND_TYPE]] && {
                [SORTABLE_COLUMNS[COLUMNS.ROUND_TYPE]]:
                    filters[SORTABLE_COLUMNS[COLUMNS.ROUND_TYPE]]
            }),
            ...(filters[SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]] && {
                [SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]]:
                    filters[SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]]
            }),
            ...(filters[SORTABLE_COLUMNS[COLUMNS.STATUS]] && {
                [SORTABLE_COLUMNS[COLUMNS.STATUS]]: filters[SORTABLE_COLUMNS[COLUMNS.STATUS]]
            })
        }
    });

    const handleSortClick = (item) => {
        setCurrentSortColumn(item.sortColumn);
        setCurrentSortOrder((prev) =>
            prev === SortOrder.ASC || !prev ? SortOrder.DESC : SortOrder.ASC
        );
    };
    return (
        <div className="mt-4">
            <Helmet>
                <title>{PageTitles.COURSE_MANAGEMENT}</title>
            </Helmet>
            <Container fluid>
                <ListingHeader
                    onSearchChange={handleSearchChange}
                    searchedText={searchText}
                    title={PageHeadings.USER_MANAGAMENT}
                />

                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    currentSortOrder={currentSortOrder}
                    currentSortColumn={currentSortColumn}
                    onSortClick={handleSortClick}
                    config={[
                        {
                            // sortColumn: SORTABLE_COLUMNS[COLUMNS.ROUND_NAME],
                            isSortable: true,
                            title: COLUMNS.ROUND_NAME,
                            render: (data) => data?.Name || "-"
                        },
                        {
                            isSortable: true,
                            title: COLUMNS.COURSE_NAME,
                            render: (data) => data?.Course?.Name || "-"
                        },

                        // {
                        //     sortColumn: SORTABLE_COLUMNS[COLUMNS.ADDRESS],
                        //     isSortable: true,
                        //     title: COLUMNS.ADDRESS,
                        //     render: (data) => data.address || "-"
                        // },

                        // {
                        //     sortColumn: SORTABLE_COLUMNS[COLUMNS.CITY],
                        //     isSortable: true,
                        //     title: COLUMNS.CITY,
                        //     render: (data) => data.city || "-"
                        // },

                        {
                            title: COLUMNS.ROUND_TYPE,
                            isFilterable: true,
                            filterConstants: RoundType,
                            filterValue: filters[SORTABLE_COLUMNS[COLUMNS.ROUND_TYPE]],
                            onFilterSelect: (value) =>
                                handleFilterChange(
                                    SORTABLE_COLUMNS[COLUMNS.ROUND_TYPE],
                                    value,
                                    handlePageClick,
                                    setFilters
                                ),
                            render: (data) => RoundType.getDisplayTextKey(data?.Type) || "-"
                        },
                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.DATE],
                            isSortable: true,
                            title: COLUMNS.DATE,
                            render: (data) =>
                                monthYear("DD/MM/YY hh:mm:ss", data.EventDateTime) || "-"
                        },

                        {
                            isFilterable: true,
                            filterConstants: SkillLevel,
                            filterValue: filters[SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]],
                            title: COLUMNS.SKILL_LEVEL,
                            onFilterSelect: (value) =>
                                handleFilterChange(
                                    SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL],
                                    value,
                                    handlePageClick,
                                    setFilters
                                ),
                            render: (data) => data.SkillLevel || "-"
                        },

                        {
                            title: COLUMNS.STATUS,
                            isFilterable: true,
                            filterConstants: RoundsStatus,
                            filterValue: filters[SORTABLE_COLUMNS[COLUMNS.STATUS]],
                            onFilterSelect: (value) =>
                                handleFilterChange(
                                    SORTABLE_COLUMNS[COLUMNS.STATUS],
                                    value,
                                    handlePageClick,
                                    setFilters
                                ),
                            render: (data) => {
                                return (
                                    <div
                                        className={`round-status-common ${RoundsStatus.getLabelClass(
                                            data.Status
                                        )}`}
                                    >
                                        {data.Status}
                                    </div>
                                );
                            }
                        }
                    ]}
                />
                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
            </Container>
        </div>
    );
};

export default DetailsRounds;
