import React, { useState } from "react";
import { Container } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";

import AdDataStructureUI from "./AdDataStructureUI";
import { LABLES, NAMES } from "./AdsFieldsNames";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { SwitchInput } from "../../components/Input";
import { ConfirmationModal } from "../../components/CustomModal";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { hasData } from "../../utils/commonUtils";
import { getStatus, getStatusRunningOrCanceled, findStatusChecked } from "./utils";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { GetAdvertisementById, UpdateAdvertisementById } from "../../api/api.service";
import { ERROR, SUCCESS, showToast } from "../../utils/toastUtils";
import { MODAL_CONTENT_CONFIG } from "./ModalContentConfig";

const AdActions = {
    ACTIVATE: 1,
    DEACTIVATE: 0
};

const AdDetails = () => {
    let history = useHistory();
    let { adId: id } = useParams();

    const [adStatus, setAdStatus] = useState(AdActions.ACTIVATE);
    const [modalConfig, setModalConfig] = useState(null);
    const [isAdStatusUpdating, setIsAdStatusUpdating] = useState(false);

    let { useFetch, useMutate } = useRequestProcessor();
    let { mutate } = useMutate("", getKey(), (data) =>
        UpdateAdvertisementById({ id, bodyData: data })
    );

    // fetching data from API
    let { data, isLoading, isError, isFetching } = useFetch(
        getKey([MODULE_KEYS.CONTENT_DETAILS]),
        async ({ signal }) => await GetAdvertisementById({ id, params: {}, signal }),
        {
            onSuccess: (res) => {
                setAdStatus(res?.Status);
            }
        }
    );

    const handleActionInit = () => {
        let statusToSet = getStatus(adStatus);
        setModalConfig(MODAL_CONTENT_CONFIG[statusToSet]);
    };

    const handleActionCancellation = () => {
        setModalConfig(null);
    };

    // handling advertisement status update

    const handleActionConfirmation = () => {
        setIsAdStatusUpdating(true);
        let statusToSet = getStatus(adStatus);
        const bodyData = { Status: statusToSet };
        // Api call here
        mutate(bodyData, {
            onSuccess: async (res) => {
                setIsAdStatusUpdating(false);
                showToast(SUCCESS, "Status has been updated successfully");
                setAdStatus(getStatusRunningOrCanceled(statusToSet));
                setModalConfig(null);
            },
            onError: (err) => {
                // showToast(ERROR, err?.Message)
                setIsAdStatusUpdating(false);
            }
        });
    };

    if (isFetching || isLoading || isError || !hasData(data)) {
        return (
            <div style={{ height: "70vh" }}>
                <LoaderFallbackUI isLoader={isFetching} className={"h-100"} />
            </div>
        );
    }
    return (
        <>
            <Container className="page-content" fluid>
                <AdDataStructureUI
                    isDetailView
                    FIELDS_TITLE={LABLES}
                    FIELDS_NAME={NAMES}
                    onEdit={() =>
                        history.push(
                            getParamsAttachedRoute(APP_ROUTES.UPDATE_AD, {
                                adId: id
                            })
                        )
                    }
                    data={data}
                >
                    {!isFetching && (<div className="ad-management-details-option-container mb-2">
                        <SwitchInput
                            size="lg"
                            isChecked={findStatusChecked(adStatus)}
                            onClick={handleActionInit}
                        />
                    </div>)}
                </AdDataStructureUI>
                <ConfirmationModal
                    size="md"
                    hasCustomConfig
                    isOpen={modalConfig}
                    customImageSrc={modalConfig?.imageSrc}
                    customTitle={modalConfig?.modalTitle}
                    customYesBtnText={modalConfig?.yesButtonText}
                    customNoBtnText={modalConfig?.noButtonText}
                    onYes={handleActionConfirmation}
                    onNo={handleActionCancellation}
                    yesLoading={isAdStatusUpdating}
                />
            </Container>
        </>
    );
};

export default AdDetails;
