export const downloadCSVData = (csvText) => {
    let blob = new Blob([csvText]);
    let link = document.createElement("a");
    link.href = "data:text/csv;charset=utf-8," + encodeURI(csvText);
    link.href = window.URL.createObjectURL(blob);
    link.download = `sample_${new Date().getTime()}.csv`;
    link.click();
};

export const downloadCSVDataDirect = (url) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const downloadXlsData = async (response) => {
    let blob = await response.blob();
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = `template_${new Date().getTime()}.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
};
