import React from "react";
import { Card, CardBody, Table } from "reactstrap";

const ListBox = ({ data = [], title = "-" }) => {
    return (
        <Card className="analytics-list-box">
            <CardBody className="p-0 analytics-list-body ">
                <Table borderless className="m-0 table-section">
                    <th colSpan="2" className="header text-center p-2">
                        {title}
                    </th>

                    <tbody className="table-body">
                        {data?.map((item) => (
                            <tr>
                                <td className="left-content">{item?.key}</td>
                                <td className="right-content">{item.value || "-"}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

export default ListBox;
