import React, { useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import { SIDEBAR_CONFIG } from "../../app-config";

const SidebarContent = (props) => {
    const ref = useRef();
    // Use ComponentDidMount and ComponentDidUpdate method symultaniously
    useEffect(() => {
        const pathName = props.location.pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            let matchingMenuItem = null;
            const ul = document.getElementById("side-menu");
            const items = ul.getElementsByTagName("a");
            for (let i = 0; i < items.length; ++i) {
                // if (pathName === items[i].pathname) {
                if (pathName.includes(items[i].pathname)) {
                    matchingMenuItem = items[i];
                    break;
                }
                // }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };
        initMenu();
    }, [props.location.pathname]);

    useEffect(() => {
        ref.current.recalculate();
    });

    const scrollElement = (item) => {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    };

    const activateParentDropdown = (item) => {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    };
    // :TODO:Need to change it as per project
    const roleBasedMenu = SIDEBAR_CONFIG.filter((item) =>
        item.permissions?.includes(props?.Role || "Admin")
    );

    return (
        <>
            <SimpleBar className="h-100" ref={ref}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {SIDEBAR_CONFIG.map((item, index) => {
                            const hasChildrens =
                                item.hasOwnProperty("children") && Array.isArray(item.children);
                            return item.type && item.type === "title" ? (
                                <li key={index} className="menu-title">
                                    {this.props.t(item.title)}
                                </li>
                            ) : (
                                <li key={index}>
                                    <Link
                                        to={() => item?.url}
                                        className={`waves-effect ${
                                            hasChildrens ? "has-arrow has-children" : ""
                                        }`}
                                    >
                                        {item.icon ? (
                                            <i className={`${item.icon}`}></i>
                                        ) : (
                                            <img src={item.menuImg} className="menu-img mr-2" />
                                        )}
                                        <span>{item.title}</span>
                                    </Link>
                                    {hasChildrens && (
                                        <ul className="sub-menu" aria-expanded="false">
                                            {item.children.map((childItem, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Link to={childItem.url}>
                                                            {childItem.title}
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </SimpleBar>
        </>
    );
};

export default withRouter(SidebarContent);
