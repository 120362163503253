import { Roles } from "../constants";
import { APP_ROUTES } from "../helpers/routeHelpers";

let { Admin, SuperAdmin } = Roles;

const SIDEBAR_CONFIG = [
    {
        title: "Dashboard",
        url: APP_ROUTES.DASHBOARD,
        icon: "bx bx-grid-alt",
        menuImg: null,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "User Management",
        url: APP_ROUTES.USER_MANAGEMENT,
        icon: "bx bx-user",
        menuImg: null,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Article Management",
        url: APP_ROUTES.CONTENT_MANAGEMENT,
        icon: "bx bx-file",
        menuImg: null,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Course Management",
        url: APP_ROUTES.COURSE_MANAGEMENT,
        icon: "bx bx-spreadsheet",
        menuImg: null,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Ad Management",
        url: APP_ROUTES.AD_MANAGEMENT,
        icon: "bx bxs-plus-square",
        menuImg: null,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Account",
        url: APP_ROUTES.ACCOUNT,
        icon: "bx bx-user-circle",
        menuImg: null,
        nestedMenu: null,
        permissions: [Admin]
    }
];

export default SIDEBAR_CONFIG;
