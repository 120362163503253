import React, { forwardRef } from "react";
import AuthCode from "react-auth-code-input";
import "./CodeInput.scss";

const CodeInput = forwardRef(
    (
        { className, characters = 4, onChange = () => null, isPassword = false, disabled = false },
        ref
    ) => {
        return (
            <div>
                <AuthCode
                    isPassword
                    allowedCharacters="numeric"
                    length={characters}
                    containerClassName="text-center"
                    inputClassName="otp-input"
                    onChange={onChange}
                    ref={ref}
                />
            </div>
        );
    }
);

export default CodeInput;
