import React from "react";
import { Card, CardBody } from "reactstrap";

const AnalyticsBoxUI = ({
    data,
    className = "",
    onClick,
    titleClass,
    showOptions = false,
    optionsData = []
}) => {
    return (
        <div className={`analytics-card-box   ${className} ${onClick && "pointer"}`}>
            <Card className="mini-stats-wid bg-theme-primary-light custom-card" onClick={onClick}>
                <CardBody>
                    <div className="d-flex justify-content-center">
                        {data.iconUrl ? (
                            <div className="align-self-center analytics-image-container">
                                <img src={data.iconUrl} className="img-fluid analytics-image" />
                            </div>
                        ) : (
                            <div className="avatar-sm rounded-circle bg-primary align-self-center overflow-hidden position-relative">
                                <span className="avatar-title bg-theme-secondary-light clr-theme-primary">
                                    <i className={"bx " + data.iconClass + " font-size-24"}></i>
                                </span>
                            </div>
                        )}
                        <div className="d-flex flex-column ">
                            <p className={`${titleClass} text-muted font-weight-medium`}>
                                {data.title}
                            </p>
                            <h4 className="mb-0">{data.description}</h4>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default AnalyticsBoxUI;
