import React, { useState } from "react";
import { Container } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";

import suspendModalImg from "../../assets/images/app/common/modal-suspend-img.svg";
import deleteModalImg from "../../assets/images/app/common/modal-delete-img.svg";
import ContentDataStructureUI from "./ContentDataStructureUI";
import { LABLES, NAMES } from "./ContentFieldsNames";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { SwitchInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { ConfirmationModal } from "../../components/CustomModal";
import { GetArticleById, DeleteArticleById, UpdateArticleById } from "../../api/api.service";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { hasData } from "../../utils/commonUtils";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { showToast, SUCCESS, ERROR } from "../../utils/toastUtils";
let ACTIONS = {
    ACTIVATE: 1,
    DEACTIVATE: 0,
    DELETE: 2
};

let MODAL_CONTENT_CONFIG = {
    [ACTIONS.DEACTIVATE]: {
        modalTitle: "Are you sure you want to deactivate this article?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: suspendModalImg
    },
    [ACTIONS.ACTIVATE]: {
        modalTitle: "Are you sure you want to activate this article?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: suspendModalImg
    },
    [ACTIONS.DELETE]: {
        modalTitle: "Are you sure you want to delete this article?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: deleteModalImg
    }
};

const ContentDetails = () => {
    let history = useHistory();
    let { contentId } = useParams();

    const [modalConfig, setModalConfig] = useState(null);
    const [articleStatus, setArticleStatus] = useState(ACTIONS.ACTIVATE);
    const [isArticleStatusUpdating, setIsArticleStatusUpdating] = useState(false);
    let { useFetch, useMutate } = useRequestProcessor();
    let { mutate } = useMutate("", getKey(), (data) => UpdateArticleById(contentId, data));
    let { mutate: deleteArticleMutate } = useMutate("", getKey(), (data) =>
        DeleteArticleById(contentId, data)
    );

    // fetching data from API
    let { data, isLoading, isError, isFetching } = useFetch(
        getKey([MODULE_KEYS.CONTENT_DETAILS]),
        async ({ signal }) => await GetArticleById(contentId, {}, signal),
        {
            onSuccess: (res) => {
                setArticleStatus(!res?.Disabled);
            }
        }
    );

    const handleActionInit = () => {
        let statusToSet = getStatus();
        setModalConfig(MODAL_CONTENT_CONFIG[statusToSet]);
    };

    const handleActionCancellation = () => {
        setModalConfig(null);
    };

    const handleDelete = () => {
        setModalConfig(MODAL_CONTENT_CONFIG[ACTIONS.DELETE]);
    };

    const getStatus = () =>
        articleStatus == ACTIONS.DEACTIVATE ? ACTIONS.ACTIVATE : ACTIONS.DEACTIVATE;

    const handleSuccessDeletion = () => {
        if (MODAL_CONTENT_CONFIG[ACTIONS.DELETE] == modalConfig) {
            handleDeleteModalConfirmation();
        } else {
            handleArticleStatusUpdate();
        }
    };

    // handling article status update
    const handleArticleStatusUpdate = () => {
        setIsArticleStatusUpdating(true);
        let statusToSet = articleStatus;
        const bodyData = { Disabled: statusToSet };
        // Api call here
        mutate(bodyData, {
            onSuccess: async (res) => {
                setIsArticleStatusUpdating(false);
                showToast(SUCCESS, "Status has been updated successfully");
                setArticleStatus(!statusToSet);
                setModalConfig(null);
            },
            onError: (err) => {
                // showToast(ERROR, err?.Message)
                setIsArticleStatusUpdating(false);
            }
        });
    };

    // handling article delete API

    const handleDeleteModalConfirmation = () => {
        setIsArticleStatusUpdating(true);
        // Api call here
        deleteArticleMutate(
            {},
            {
                onSuccess: async (res) => {
                    setIsArticleStatusUpdating(false);
                    showToast(SUCCESS, "Article has been deleted successfully");
                    setModalConfig(null);
                    history.push(APP_ROUTES.CONTENT_MANAGEMENT);
                },
                onError: (err) => {
                    // showToast(ERROR, err?.Message);
                    setIsArticleStatusUpdating(false);
                }
            }
        );
    };

    if (isFetching || isLoading || isError || !hasData(data)) {
        return (
            <div style={{ height: "70vh" }}>
                <LoaderFallbackUI isLoader={isFetching} className={"h-100"} />
            </div>
        );
    }
    return (
        <>
            <Container className="page-content " fluid>
                <ContentDataStructureUI
                    isDetailView
                    FIELDS_TITLE={LABLES}
                    FIELDS_NAME={NAMES}
                    onEdit={() =>
                        history.push(
                            getParamsAttachedRoute(APP_ROUTES.CONTENT_MANAGEMENT_UPDATE, {
                                contentId: contentId
                            })
                        )
                    }
                    data={data}
                >
                    <div className="user-management-details-option-container mb-2">
                        <div className="user-management-toggle-container p-2">
                            <SwitchInput
                                size="lg"
                                isChecked={articleStatus}
                                onClick={handleActionInit}
                            />
                        </div>
                        <div className="user-management-delete-container p-2">
                            <CustomButton
                                title={"Delete"}
                                className={`custom-btn-action-red`}
                                onClick={handleDelete}
                                type="button"
                            />
                        </div>
                    </div>
                </ContentDataStructureUI>

                <ConfirmationModal
                    size="md"
                    hasCustomConfig
                    isOpen={modalConfig}
                    customImageSrc={modalConfig?.imageSrc}
                    customTitle={modalConfig?.modalTitle}
                    customYesBtnText={modalConfig?.yesButtonText}
                    customNoBtnText={modalConfig?.noButtonText}
                    onYes={handleSuccessDeletion}
                    onNo={handleActionCancellation}
                    yesLoading={isArticleStatusUpdating}
                />
            </Container>
        </>
    );
};

export default ContentDetails;
