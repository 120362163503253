import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";

import ProfileDataStructureUI from "./ProfileDataStructureUI";
import { LABLES, NAMES } from "./AccountFieldsNames";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { UpdateProfileAPI, MediaUpload } from "../../api/api.service";
import { getMe } from "../../store/actions";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { SUCCESS, ERROR, showToast } from "../../utils/toastUtils";

const UpdateProfile = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [imageFile, setImageFile] = useState("");

    let { useMutate } = useRequestProcessor();
    let { mutate } = useMutate("", getKey([MODULE_KEYS.PROFILE_UPDATED]), (data) =>
        UpdateProfileAPI(data)
    );

    const handleFormSubmit = async (values) => {
        setIsFormSubmitting(true);
        let imageId;
        if (imageFile !== "") {
            try {
                const formData = new FormData();
                formData.append("file", imageFile);
                const { Id } = await MediaUpload(formData);
                imageId = Id * 1;
            } catch (err) {
                setIsFormSubmitting(false);
                return;
            }
        }
        const { email, fullName } = values;
        const bodyData = {
            Email: email,
            FullName: fullName
        };
        bodyData.ProfileImageId = imageId;
        mutate(bodyData, {
            onSuccess: async (res) => {
                setIsFormSubmitting(false);
                showToast(SUCCESS, "Profile has been updated successfully");
                await dispatch(getMe());
                handleUpdateProfile();
            },
            onError: (err) => {
                setIsFormSubmitting(false);
            }
        });
    };
    const handleUpdateProfile = () => history.push(APP_ROUTES.ACCOUNT_PROFILE);
    const handleCancel = () => history.push(APP_ROUTES.ACCOUNT_PROFILE);

    return (
        <>
            <Container className="page-content" fluid>
                <ProfileDataStructureUI
                    isUpdate
                    FIELDS_TITLE={LABLES}
                    FIELDS_NAME={NAMES}
                    onCancel={handleCancel}
                    onSubmitHandling={handleFormSubmit}
                    isButtonLoading={isFormSubmitting}
                    onImageUpload={setImageFile}
                />
            </Container>
        </>
    );
};

export default UpdateProfile;
