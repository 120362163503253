import React, { useState } from "react";

import TextInput from "./TextInput";

let SHOW_ICON = <span className="bx bx-show"></span>;
let HIDE_ICON = <span className="bx bxs-hide"></span>;
let TYPE_PASSWORD = "password";
let TYPE_TEXT = "text";

const PasswordInput = ({ type, ...rest }) => {
    const [customType, setCustomType] = useState(TYPE_PASSWORD);

    const handlePasswordToggle = () => {
        if (customType == TYPE_TEXT) {
            setCustomType(TYPE_PASSWORD);
        } else {
            setCustomType(TYPE_TEXT);
        }
    };
    return (
        <>
            <TextInput type={customType} inputClasses={"input-icon-padding"} {...rest}>
                <div className="input-icon cursor-pointer" onClick={handlePasswordToggle}>
                    {customType == TYPE_PASSWORD ? SHOW_ICON : HIDE_ICON}
                </div>
            </TextInput>
        </>
    );
};

export default PasswordInput;
