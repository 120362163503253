import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";

import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import ListingHeader from "../../components/DataTable/ListingHeader"; 
import { GetRounds } from "../../api/api.service";
import { convertDateTime, dateTimeFormat } from "../../utils/dateFormatUtils";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { PageHeadings, SortOrder, RoundsStatus, RoundType } from "../../constants";
import { handleFilterChange } from "../../utils/tableUtils";
import { MODULE_KEYS } from "../../api/api.keys";
import { getTwoStrConcat } from "../../utils/commonUtils";

const COLUMNS = {
    ROUND_NAME: "Round Name",
    COURSE_NAME: "Course Name",
    ADDRESS: "Address",
    CITY: "City",
    ROUND_TYPE: "Round Type",
    SKILL_LEVEL: "Skill Level",
    DATE: "Date/Time",
    STATUS: "Status"
};

const SORTABLE_COLUMNS = {
    [COLUMNS.ROUND_NAME]: "Round Name",
    [COLUMNS.COURSE_NAME]: "Course Name",
    [COLUMNS.ADDRESS]: "Address",
    [COLUMNS.CITY]: "City",
    [COLUMNS.ROUND_TYPE]: "Type",
    [COLUMNS.DATE]: "EventDateTime",
    [COLUMNS.STATUS]: "Status"
};

const Rounds = ({}) => {
    let { userId } = useParams();

    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);

    const [filters, setFilters] = useState({
        [SORTABLE_COLUMNS[COLUMNS.ROUND_TYPE]]: null,
        [SORTABLE_COLUMNS[COLUMNS.STATUS]]: null
    });

    const {
        isFetching,
        page,
        total,
        data,
        searchText,
        refetch,
        limit,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: MODULE_KEYS.USER_DETAILS_ROUNDS,
        requestFn: GetRounds,
        params: {
            UserId: userId,
            ...(currentSortColumn &&
                currentSortOrder && { Column: currentSortColumn, Direction: currentSortOrder }),
            ...(filters[SORTABLE_COLUMNS[COLUMNS.ROUND_TYPE]] && {
                [SORTABLE_COLUMNS[COLUMNS.ROUND_TYPE]]:
                    filters[SORTABLE_COLUMNS[COLUMNS.ROUND_TYPE]]
            }),
            ...(filters[SORTABLE_COLUMNS[COLUMNS.STATUS]] && {
                [SORTABLE_COLUMNS[COLUMNS.STATUS]]: filters[SORTABLE_COLUMNS[COLUMNS.STATUS]]
            })
        }
    });

    const handleSortClick = (item) => {
        setCurrentSortColumn(item.sortColumn);
        setCurrentSortOrder((prev) =>
            prev === SortOrder.ASC || !prev ? SortOrder.DESC : SortOrder.ASC
        );
    };

    return (
        <div className="mt-4">
            <Helmet>
                <title>User Management</title>
            </Helmet>
            <Container fluid>
                <ListingHeader
                    onSearchChange={handleSearchChange}
                    searchedText={searchText}
                    title={PageHeadings.USER_MANAGAMENT}
                />

                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    currentSortOrder={currentSortOrder}
                    currentSortColumn={currentSortColumn}
                    onSortClick={handleSortClick}
                    config={[
                        {
                            // sortColumn: SORTABLE_COLUMNS[COLUMNS.ROUND_NAME],
                            // isSortable: true,
                            title: COLUMNS.ROUND_NAME,
                            render: (data) => data?.Name || "-"
                        },

                        {
                            // sortColumn: SORTABLE_COLUMNS[COLUMNS.COURSE_NAME],
                            // isSortable: true,
                            title: COLUMNS.COURSE_NAME,
                            render: (data) => data?.Course?.Name || "-"
                        },

                        {
                            // sortColumn: SORTABLE_COLUMNS[COLUMNS.ADDRESS],
                            // isSortable: true,
                            title: COLUMNS.ADDRESS,
                            render: (data) => data?.Course?.Address || "-"
                        },

                        {
                            // sortColumn: SORTABLE_COLUMNS[COLUMNS.CITY],
                            // isSortable: true,
                            title: COLUMNS.CITY,
                            render: (data) => data?.Course?.City || "-"
                        },

                        {
                            title: COLUMNS.ROUND_TYPE,
                            isFilterable: true,
                            filterConstants: RoundType,
                            filterValue: filters[SORTABLE_COLUMNS[COLUMNS.ROUND_TYPE]],
                            onFilterSelect: (value) =>
                                handleFilterChange(
                                    SORTABLE_COLUMNS[COLUMNS.ROUND_TYPE],
                                    value,
                                    handlePageClick,
                                    setFilters
                                ),
                            render: (data) => RoundType.getDisplayTextKey(data?.Type) || "-"
                        },
                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.DATE],
                            isSortable: true,
                            title: COLUMNS.DATE,
                            render: (data) =>
                                convertDateTime({
                                    date: data?.EventDateTime,
                                    customFormat: dateTimeFormat.appDateTimeFormat
                                }) || "-"
                        },

                        {
                            title: COLUMNS.STATUS,
                            isFilterable: true,
                            filterConstants: RoundsStatus,
                            filterValue: filters[SORTABLE_COLUMNS[COLUMNS.STATUS]],
                            onFilterSelect: (value) =>
                                handleFilterChange(
                                    SORTABLE_COLUMNS[COLUMNS.STATUS],
                                    value,
                                    handlePageClick,
                                    setFilters
                                ),
                            render: (data) => {
                                return (
                                    <div
                                        className={`round-status-common ${RoundsStatus.getLabelClass(
                                            data?.Status
                                        )}`}
                                    >
                                        {data?.Status}
                                    </div>
                                );
                            }
                        }
                    ]}
                />
                <Pagination
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                    rowLimit={limit}
                />
            </Container>
        </div>
    );
};

export default Rounds;
