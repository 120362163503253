import React from "react";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";

import ProfileDataStructureUI from "./ProfileDataStructureUI";
import { LABLES, NAMES } from "./AccountFieldsNames";
import { APP_ROUTES } from "../../helpers/routeHelpers";
 
const Profile = () => {
    let history = useHistory();

    return (
        <>
            <Container fluid>
                <ProfileDataStructureUI
                    isDetailView
                    FIELDS_TITLE={LABLES}
                    FIELDS_NAME={NAMES}
                    onEdit={() => history.push(APP_ROUTES.UPDATE_ACCOUNT_PROFILE)}
                />
            </Container>
        </>
    );
};

export default Profile;
