import { combineReducers } from "redux";

import { SIGN_OUT } from "../actions";
import Layout from "../layout/reducer";
import authReducer from "./authReducer";
import root from "./rootReducer";
import { APP_ROUTES } from "../../helpers/routeHelpers";

const appReducer = combineReducers({
    root,
    Layout,
    // Dashboard,
    // DashboardSaas,
    userAuth: authReducer
});

const rootReducer = (state, action) => {
    if (action.type === SIGN_OUT) {
        const { Layout, deviceId } = state;
        state = { Layout, deviceId };
        localStorage.clear();
    }

    return appReducer(state, action);
};

export default rootReducer;
