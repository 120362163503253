import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { GoogleApiWrapper } from "google-maps-react";
import LoadingGoogleAPI from './LoadingGoogleAPI';
import "./GoogleAutocomplete.scss";
import Config from "../../Config";

let LoadingContainer = () => <LoadingGoogleAPI />;

const GoogleAutocomplete = (props) => {
    const {
        className = "hide-default-icon",
        value,
        onChange,
        containerClass,
        inputStyles = "",
        name = "GoogleAutoComplete",
        handleOnBlur = () => {},
        onFocusHandler = () => {},
        onLocationSelect,
        placeholder = "",
        locationTypes = null,
        componentRestrictions = null,
        label = "",
    } = props;
    const placeInputRef = useRef(null);

    useEffect(() => {
        const initPlaceAPI = () => {
            let autocomplete = new props.google.maps.places.Autocomplete(
                placeInputRef.current,
                componentRestrictions
            );
            new props.google.maps.event.addListener(autocomplete, "place_changed", function () {
                let place = autocomplete.getPlace();
                onLocationSelect(place);
            });
        };

        initPlaceAPI();
    }, []);

    const handleInputFocus = () => {
        onFocusHandler();
        placeInputRef.current.setAttribute("autoComplete", "new-password");
    };


    
    return (
        <div className={`GoogleAutoComplete ${containerClass} `}>
            {label && <p className="input-label clr-theme-primary fw-bold mb-2">{label}</p>}
            <div className={`custom-text-input  ${className} `}>
                <input
                    placeholder={placeholder}
                    name={name}
                    ref={placeInputRef}
                    value={value}
                    onChange={onChange}
                    className={"form-control " + inputStyles}
                    type="text"
                    autoComplete="new-password"
                    onFocus={handleInputFocus}
                    onBlur={handleOnBlur}
                />
            </div>
        </div>
    );
};

GoogleAutocomplete.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.any,
    inputStyles: PropTypes.any,
    name: PropTypes.any,
    handleOnBlur: PropTypes.func,
    onFocusHandler: PropTypes.func,
    containerClass: PropTypes.string,
    google: PropTypes.any,
    onLocationSelect: PropTypes.any,
    placeholder: PropTypes.string,
    locationTypes: PropTypes.any
};

export default GoogleApiWrapper({
    apiKey: Config.env().GOOGLE_API_KEY,
    LoadingContainer: LoadingContainer
    // v: "3"
})(GoogleAutocomplete);
