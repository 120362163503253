export default function LoadingGoogleAPI () {
  return(
    <div className={`GoogleAutoComplete `}>
        <p className="input-label clr-theme-primary fw-bold mb-2">{"Address"}</p>
        <div className={`custom-text-input hide-default-icon`}>
            <input
                placeholder={"1600 Neptune Dr. San Leandro, CA 94577"}
                type="text"
                className={"form-control course-input"}
                autoComplete="new-password"
                disabled
            />
        </div>
    </div>
  )
} 