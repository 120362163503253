import React, { useState } from "react";
import { Container } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";

import ContentDetailsStructureUI from "./ContentDataStructureUI";
import { LABLES, NAMES } from "./ContentFieldsNames";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { GetArticleById, UpdateArticleById } from "../../api/api.service";
import { hasData } from "../../utils/commonUtils";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { ERROR, SUCCESS, showToast } from "../../utils/toastUtils";
import { uploadMediaToServer } from "../../utils/mediaUtils";

let { ARTICLE_NAME, WEBSITE_NAME, WEBSITE_LINK, TEASER_TEXT, SUBTITLES, SKILL_LEVEL, TOPIC } = LABLES;

const UpdateContent = () => {
    let history = useHistory();
    let { contentId } = useParams();

    const [contentImage, setContentImage] = useState("");
    const [isImageUploading, setIsImageUploading] = useState(false);
    let { useFetch, useMutate } = useRequestProcessor();

    let { mutate, isLoading: isUpdating } = useMutate(
        "",
        getKey([MODULE_KEYS.UPDATE_CONTENT]),
        (data) => UpdateArticleById(contentId, data)
    );

    let { data, isLoading, isError, isFetching } = useFetch(
        getKey([MODULE_KEYS.CONTENT_DETAILS]),
        async ({ signal }) => await GetArticleById(contentId, {}, signal)
    );

    const handleCancel = () =>
        history.push(
            getParamsAttachedRoute(APP_ROUTES.CONTENT_MANAGEMENT_DETAILS, { contentId: contentId })
        );

    const handleUpdateContent = async (data) => {
        let imageId;
        if (contentImage !== "") {
            imageId = await uploadMediaToServer(contentImage, setIsImageUploading);
        }
        const bodyData = {
            Title: data[NAMES[ARTICLE_NAME]],
            Description: data[NAMES[TEASER_TEXT]],
            ...(imageId && { MediaId: imageId }),
            Url: data[NAMES[WEBSITE_LINK]],
            WebsiteName: data[NAMES[WEBSITE_NAME]],
            Subtitle: data[NAMES[SUBTITLES]],
            SkillLevel: data[NAMES[SKILL_LEVEL]] === "Advanced/Pro" ? "Advanced" : data[NAMES[SKILL_LEVEL]],
            Topic: data[NAMES[TOPIC]]
        };
        mutate(bodyData, {
            onSuccess: (res) => createContentSuccessHandler(),
            onError: (err) => createContentErrorHandler(err)
        });
    };

    const createContentSuccessHandler = () => {
        showToast(SUCCESS, "Article has been updated successfully");
        history.push(
            getParamsAttachedRoute(APP_ROUTES.CONTENT_MANAGEMENT_DETAILS, { contentId: contentId })
        );
    };
    const createContentErrorHandler = (error) => {
        // showToast(ERROR, error?.Message)
    };

    if (isFetching || isLoading || isError || !hasData(data)) {
        return (
            <div style={{ height: "70vh" }}>
                <LoaderFallbackUI isLoader={isFetching} className={"h-100"} />
            </div>
        );
    }

    return (
        <>
            <Container className="page-content content-container" fluid>
                <ContentDetailsStructureUI
                    isUpdate
                    FIELDS_TITLE={LABLES}
                    FIELDS_NAME={NAMES}
                    onCancel={handleCancel}
                    onSave={handleUpdateContent}
                    data={data}
                    setContentImage={setContentImage}
                    isFormSubmitting={isUpdating || isImageUploading}
                />
            </Container>
        </>
    );
};

export default UpdateContent;
