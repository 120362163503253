import React, { useState } from "react";
import { Container } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";

import AdDataStructureUI from "./AdDataStructureUI";
import { LABLES, NAMES } from "./AdsFieldsNames";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { hasData } from "../../utils/commonUtils";
import { GetAdvertisementById, UpdateAdvertisementById } from "../../api/api.service";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { ERROR, SUCCESS, showToast } from "../../utils/toastUtils";
import { uploadMediaToServer } from "../../utils/mediaUtils";

let { ADVERTISEMENT_LINK, ADVERTISEMENT_NAME, ADVERTISERS_NAME, ADVERTISERS_EMAIL } = LABLES;
 
const UpdateAd = () => {
    let history = useHistory();
    let { adId: id } = useParams();

    const [adImageContent, setAdImageContent] = useState("");
    const [isImageUploading, setIsImageUploading] = useState(false);

    let { useFetch, useMutate } = useRequestProcessor();

    let { data, isLoading, isError, isFetching } = useFetch(
        getKey([MODULE_KEYS.CONTENT_DETAILS]),
        async ({ signal }) => await GetAdvertisementById({ id, params: {}, signal })
    );

    let { mutate, isLoading: isUpdating } = useMutate("", getKey([MODULE_KEYS.UPDATE_AD]), (data) =>
        UpdateAdvertisementById({ id, bodyData: data })
    );
    const handleUpdateAd = async (values) => {
        let imageId;
        if (adImageContent !== "") {
            imageId = await uploadMediaToServer(adImageContent, setIsImageUploading);
        }
        const bodyData = {
            Title: values[NAMES[ADVERTISEMENT_NAME]],
            AdvertiserName: values[NAMES[ADVERTISERS_NAME]],
            AdvertiserEmail: values[NAMES[ADVERTISERS_EMAIL]]?.toLowerCase(),
            Url: values[NAMES[ADVERTISEMENT_LINK]],
            ...(imageId && { MediaId: imageId })
        };
        mutate(bodyData, {
            onSuccess: (res) => createAdSuccessHandler(),
            onError: (err) => createAdErrorHandler(err)
        });
    };

    const createAdSuccessHandler = () => {
        showToast(SUCCESS, "Advertisement has been updated successfully");
        history.push(getParamsAttachedRoute(APP_ROUTES.AD_DETAILS, { adId: id }));
    };
    const createAdErrorHandler = (error) => {};

    const handleCancel = () =>
        history.push(getParamsAttachedRoute(APP_ROUTES.AD_DETAILS, { adId: id }));

    if (isFetching || isLoading || isError || !hasData(data)) {
        return (
            <div style={{ height: "70vh" }}>
                <LoaderFallbackUI isLoader={isFetching} className={"h-100"} />
            </div>
        );
    }

    return (
        <>
            <Container className="page-content" fluid>
                <AdDataStructureUI
                    isUpdate
                    FIELDS_TITLE={LABLES}
                    FIELDS_NAME={NAMES}
                    onCancel={handleCancel}
                    onSave={handleUpdateAd}
                    data={data}
                    setContentImage={setAdImageContent}
                    isFormSubmitting={isUpdating || isImageUploading}
                />
            </Container>
        </>
    );
};

export default UpdateAd;
