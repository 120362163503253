import { useState } from "react";
import { useFormik } from "formik";

import successImg from "../../assets/images/app/common/modal-success-img.svg";
import { Card, Container, CardBody, Row, Col } from "reactstrap";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { resetPassFormValidationSchema, initialValues } from "./FormValidations";
import { PasswordInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { CustomModal } from "../../components/CustomModal";
import { LABLES, NAMES, PLACEHOLDERS } from "./AccountFieldsNames";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { ChangePasswordAccount } from "../../api/api.service";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";

const ChangePassword = () => {
    let { PASSWORD, NEW_PASSWORD, CONFIRM_PASSWORD } = LABLES;

    const [showResetSuccessModal, setShowResetSuccessModal] = useState(false);

    const formik = useFormik({
        initialValues: initialValues.changePassword,
        validationSchema: resetPassFormValidationSchema,
        onSubmit: (values, { resetForm }) => {
            handleFormSubmit(values, resetForm);
        }
    });

    let { useMutate } = useRequestProcessor();
    let { mutate, isLoading } = useMutate(
        "",
        getKey([MODULE_KEYS.ACCOUNT_CHANGE_PASSWORD]),
        (data) => ChangePasswordAccount(data)
    );

    const handleFormSubmit = (values, resetForm) => {
        const { oldPassword, newPassword } = values;
        let bodyData = {
            OldPassword: oldPassword,
            NewPassword: newPassword
        };
        mutate(bodyData, {
            onSuccess: (res) => {
                setShowResetSuccessModal(true);
                resetForm();
            },
            onError: (err) => {}
        });
    };

    return (
        <Card className="custom-card mt-3">
            <form onSubmit={formik.handleSubmit}>
                <Container fluid>
                    <CardBody>
                        <Row>
                            <Col sm="12" md="6" lg="6">
                                <PasswordInput
                                    placeholder={PLACEHOLDERS[PASSWORD]}
                                    label={PASSWORD}
                                    name={NAMES[PASSWORD]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} 
                                    value={formik.values[NAMES[PASSWORD]]}
                                    size="lg"
                                />
                                <FormikErrorText
                                formikInstance = {formik}
                                fieldName = {NAMES[PASSWORD]}
                                    />
                            </Col>
                        </Row>
                        <Row className="my-md-3 my-lg-3">
                            <Col sm="12" md="6" lg="6">
                                <PasswordInput
                                    placeholder={PLACEHOLDERS[NEW_PASSWORD]}
                                    label={NEW_PASSWORD}
                                    name={NAMES[NEW_PASSWORD]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values[NAMES[NEW_PASSWORD]]}
                                    size="lg"
                                />
                                <FormikErrorText
                                formikInstance = {formik}
                                fieldName = {NAMES[NEW_PASSWORD]}
                                    />
                            </Col>
                            <Col sm="12" md="6" lg="6">
                                <PasswordInput
                                    placeholder={PLACEHOLDERS[CONFIRM_PASSWORD]}
                                    label={CONFIRM_PASSWORD}
                                    name={NAMES[CONFIRM_PASSWORD]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values[NAMES[CONFIRM_PASSWORD]]}
                                    size="lg"
                                />
                                <FormikErrorText
                                formikInstance = {formik}
                                fieldName = {NAMES[CONFIRM_PASSWORD]}
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="3" lg="3">
                                <CustomButton
                                    loading={isLoading}
                                    type={"submit"}
                                    title="Save Password"
                                    color="primary"
                                    className="custom-btn-lg w-100 mt-2"
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Container>
            </form>

            <CustomModal isOpen={showResetSuccessModal} size="md">
                <div className="text-center">
                    <img src={successImg} className="mb-4" />
                    <h4 className="font-clr-theme-secondary fw-bold">
                        Password Reset Successfully
                    </h4>
                    <p className="my-4 font-clr-theme-secondary">Your password has been reset</p>
                    <CustomButton
                        color="primary"
                        title="Done"
                        className="custom-btn-sm"
                        onClick={() => setShowResetSuccessModal(false)}
                    />
                </div>
            </CustomModal>
        </Card>
    );
};

export default ChangePassword;
