import React from "react";

import { CustomButton } from "../../CustomButton";
import AutoComplete from "../../AutoComplete/AutoComplete";
import { customStyles } from "./customStyles";
import {
    monthYear,
    generateYearOptions,
    currentYear,
    generateMonthOptions
} from "../../../utils/dateUtils";

const DATE_FORMAT = "MMMM";
const DEFAULT_DATE_FORMAT = "MMMM, YYYY";
const START_YEAR = 2023;
const MONTH = "month";
const YEAR = "year";

const currentMonthYear = monthYear(DEFAULT_DATE_FORMAT);
const yearOptions = generateYearOptions(START_YEAR);
const monthOptions = generateMonthOptions(DATE_FORMAT);

const GraphFilter = ({isFilterable = false, getFilterData=() => {}, filterData={}}) => {

    const handleClick = (e, buttonId) => {
        e.preventDefault();
        let tempSelectedVal;
        switch (buttonId) {
            case MONTH:
                tempSelectedVal = currentMonthYear;
                break;
            case YEAR:
                tempSelectedVal = currentYear.toString();
                break;
            default:
        }
        getFilterData(tempSelectedVal, buttonId)
    };

    const handleChange = (selectedVal) => {
        getFilterData(selectedVal, filterData.type)
    }
    return (
        <div className="clearfix filters-btn">
            {isFilterable && (
                <div className="float-end d-flex filter-btn-con">
                    <CustomButton
                        title="Month"
                        className={`chart-button month-btn shadow-none me-2 ${
                            filterData?.type === MONTH ? "active" : ""
                        }`}
                        color="#704DE7"
                        outline
                        onClick={(e) => handleClick(e, MONTH)}
                    />
                    <CustomButton
                        className={`chart-button year-btn shadow-none me-2 ${
                            filterData?.type === YEAR ? "active" : ""
                        }`}
                        color="#704DE7"
                        outline
                        onClick={(e) => handleClick(e, YEAR)}
                        title="Year"
                    />
                    <AutoComplete
                        customStyles={customStyles.datePicker}
                        options={filterData?.type == MONTH ? monthOptions : yearOptions}
                        className="date-picker"
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: "#2795ae"
                            }
                        })}
                        maxMenuHeight={200}
                        value={{
                            label: filterData?.date,
                            value: filterData?.date
                        }}
                        onChange={(selectedOption) => handleChange(selectedOption.label)}
                        placeholder={`Select ${filterData?.type === MONTH ? "Month" : "Year"}`}
                    />
                </div>
            )}
        </div>
    );
};

export default GraphFilter;
