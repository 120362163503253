import React from "react";
import { useParams } from "react-router-dom";

import Profile from "./Profile";
import Connections from "./Connections";
import Rounds from "./Rounds";
import { RoutingTabs } from "../../components/RoutingTabs";
import { APP_ROUTES } from "../../helpers/routeHelpers";

const UserDetails = () => {
    let { userId } = useParams();

    let CONFIG = {
        param: { userId },
        tabsConfig: [
            {
                title: "Profile",
                id: "1",
                defaultActive: true,
                path: APP_ROUTES.USER_MANAGEMENT_PROFILE,
                component: <Profile />
            },

            {
                title: "Rounds",
                id: "2",
                defaultActive: false,
                path: APP_ROUTES.USER_MANAGEMENT_ROUNDS,
                component: <Rounds />
            },
            {
                title: "Connections",
                id: "3",
                defaultActive: false,
                path: APP_ROUTES.USER_MANAGEMENT_CONNECTIONS,
                component: <Connections />
            }
        ]
    };

    return (
        <div className="page-content">
            <RoutingTabs
                config={CONFIG}
                isPills
                isTabs={false}
                parentRoute={APP_ROUTES.USER_MANAGEMENT_DETAILS}
            />
        </div>
    );
};

export default UserDetails;
